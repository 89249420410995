import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/contract";
const url = `${configs.urlRoot}/contract`;

const getContracts = () => {
    return http.get(url);
};

const getContractById = (id: number) => {
    return http.get(url+"/"+id);
}

const getContractByNo = (no: string) => {
    return http.get(url+"/byNo?no="+no);
}

const getDebitbyContractId = (id: number) => {
    return http.get(url+"/getDebitbyContractId/"+id);
}

const getForPaymentByContractId = (id: number) => {
    return http.get(url+"/getForPaymentByContractId?contractId="+id);
}

const getByParam = (contractNo: string, idCard: string) => {
    return http.get(url+"/param?contractNo="+contractNo+"&idCard="+idCard);
}

const calculateDueDate = (id: number, contractDate: string) => {
    return http.get(url+"/calculateDueDate?id="+id+"&contractDate="+contractDate);
}

const updateContractDate = (contract: any) => {
    return http.put(url, contract);
}

export default {
    getContracts,
    getContractById,
    getContractByNo,
    getDebitbyContractId,
    getForPaymentByContractId,
    getByParam,
    calculateDueDate,
    updateContractDate
}