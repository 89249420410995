import { Button, Descriptions, Modal, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';
import moment from 'moment';
import 'moment/locale/th';
import paymentService from 'src/services/payment/paymentService';
import { Payment } from 'src/domain/payment/paymentType';
import { PaymentDetail } from 'src/domain/payment/paymentDetailType';
import { DepositDetail } from 'src/domain/payment/depositDetailType';
import depositService from 'src/services/payment/depositService';
import { useAppDispatch, useAppSelector } from "src/store";

const { Title} = Typography;

const dateFormat = 'DD/MM/YYYY';

interface IProps {
    // paymentNo: any;
    // payment: Payment;
    // paymentDetails?: PaymentDetail[];
    // itemDeposit?: PaymentDetail;
    // depositDetails?: DepositDetail[];
    modalVisible: boolean;
    onCancel: () => void;
}
const ViewPaymentPopup : React.FC<IProps> = (props) => {
    // const {payment, paymentDetails, itemDeposit, depositDetails, modalVisible, onCancel} = props;
    const {modalVisible, onCancel} = props;
    const { payment, paymentDetails, itemDeposit, depositDetails } = useAppSelector((state) => state.payment);
    // const [payment, setPayment] = useState<Payment>({} as Payment);
    // const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
    // const [itemDeposit, setItemDeposit] = useState<PaymentDetail>({} as PaymentDetail);
    // const [depositDetails, setDepositDetails] = useState<DepositDetail[]>([]);

    // useEffect(() => {
    //     // (async () => {
    //         if(paymentNo) {
    //             // const res = await paymentService.getPaymentByNo(paymentNo);
    //             // paymentService.getPaymentByNo(paymentNo).then(res => {
    //             // const _payment = res.data;
    //             console.log("_payment", payment)
    //             if(payment) {
    //                 for(let x=0; x<payment.paymentDetails.length; x++) {
    //                     const _detail = payment.paymentDetails[x];
    //                     if(_detail.paymentItemId === 48) setItemDeposit(_detail);
    //                     else paymentDetails.push(_detail);
    //                 }
    //             }

    //             depositService.getByPaymentNoAndType(payment.paymentNo, "1").then(res => {
    //                 setDepositDetails(res.data);
    //             });

    //         //     setPayment(_payment);
    //         // });
    //         }
    //     // })();
    // }, []);


    // console.log("payment no", paymentNo)
    return (
        <div>
            <Modal
                title="View Payment"
                style={{ top: 120 }}
                visible={modalVisible}
                onCancel={() => onCancel()}
                footer={[
                    // <Button key="back" onClick={() => onCancel()}>
                    //     ปิด
                    // </Button>
                ]}
            >
                <Panel style={{marginTop: 10,}}>
                    <Typography>
                        <Title level={3}>ข้อมูลการรับชำระ</Title>
                    </Typography>
                    <Descriptions
                        column={1}
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="เลขที่ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            <label style={{fontWeight: "bold", fontSize:"medium"}}>{payment.paymentNo}</label>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่รับชำระ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            <label style={{fontWeight: "bold", fontSize:"medium"}}>{moment(payment.paymentDate).format(dateFormat)}</label>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="การรับเงิน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.paymentType}
                        </Descriptions.Item>

                        {payment.paymentDetails ? 
                        <Descriptions.Item
                            label="งวดที่ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {paymentDetails.length > 0 ? paymentDetails[0].month : 0 }
                        </Descriptions.Item>
                        : "" }

                        <Descriptions.Item
                            label="จำนวนเงินรับชำระ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.totalAmt ? Number(Number(payment.totalAmt).toFixed(2)).toLocaleString('uth-TH') : 0}
                        </Descriptions.Item>

                        {itemDeposit && itemDeposit.amt ?
                            <Descriptions.Item
                                label="เงินฝาก ยกมา :"
                                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                            >
                                {itemDeposit ? Number(Number(itemDeposit.amt).toFixed(2)).toLocaleString('uth-TH') : 0}
                            </Descriptions.Item>
                        :""}
                    </Descriptions>
                    <hr/>
                    <Descriptions
                        column={1}
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        {paymentDetails ? paymentDetails.map((item) => (
                            <Descriptions.Item
                                label={item.paymentItemName + " :"}
                                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                                key={item.id}
                            >
                                {item.amt ? Number(Number(item.amt).toFixed(2)).toLocaleString('uth-TH') : 0}
                            </Descriptions.Item>
                        )) : ""}

                        {depositDetails ? depositDetails.map((item) => (
                            <Descriptions.Item
                            label="เงินฝาก :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {item.depositAmt ? Number(Number(item.depositAmt).toFixed(2)).toLocaleString('uth-TH') : 0}
                        </Descriptions.Item>
                        )) : ""}
                    </Descriptions>
                </Panel>
            </Modal>
        </div>
    )
}

export default ViewPaymentPopup
