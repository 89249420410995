import { Button, Steps, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {CloseCircleOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel';
import FindContract from './FindContract';
import ContractDetail from './ContractDetail';
import PaymentDetail from './PaymentDetail';
import ShowContract from './ShowContract';
import contractService from 'src/services/contract/contractService';
import { Contract } from 'src/domain/contract/contractType';
import { Application } from 'src/domain/application/applicationType';
import { Address } from 'src/domain/application/addressType';
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import campaignService from 'src/services/master/campaignService';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import { Deposit } from 'src/domain/payment/depositType';
import depositService from 'src/services/payment/depositService';
import { Payment } from 'src/domain/payment/paymentType';
import paymentService from 'src/services/payment/paymentService';

const Step = Steps.Step;

const NewPayment = () => {
    const history = useHistory();

    const [currentState, setCurrentState] = useState<number>(0);
    const [selectContractId, setSelectContranctId] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);

    const [payment, setPayment] = useState<Payment>({} as Payment);
    const [contract, setContract] = useState<Contract>({} as Contract);
    const [contractDebit, setContractDebit] = useState<ContractDebit>({} as ContractDebit);
    const [application, setApplication] = useState<Application>({} as Application);
    const [address, setAddress] = useState<Address>({} as Address);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);
    const [deposit, setDeposit] = useState<Deposit>({} as Deposit);

    useEffect(() => {
        console.log("selectContractId", selectContractId)
        if(selectContractId) {
            contractService.getContractById(selectContractId).then(res => {
                const _contract = res.data;
                const _app = _contract.application;
                for(let x=0; x<_app.addresses.length; x++) {
                    const _addr = _app.addresses[x];
                    if(_addr.addressType === "CURRENT") setAddress(_addr);
                }
                setApplication(_app);

                if(_contract && _contract.campaignId) {
                    campaignService.getCampaignById(_contract.campaignId).then(res => {
                        setCampaign(res.data);
                    });
                }
    
                if(_contract && _contract.campaignHeadId) {
                    campaignHeadService.getCampaignHeadById(_contract.campaignHeadId).then(res => {
                        setCampaignHead(res.data);
                    });
                }

                if(_contract && _contract.contractNo) {
                    depositService.getByContract(_contract.contractNo).then(res => {
                        setDeposit(res.data);
                    });
                }
                setContract(_contract);
            });

            contractService.getForPaymentByContractId(selectContractId).then(res => {
                setContractDebit(res.data);
            });            
        }
    }, [selectContractId]);

    useEffect(() => {}, [balance]);

    useEffect(() => {
        if (payment.isFinish) saveDataToDB();
    }, [payment]);

    const updateSelectContract = (id: number) => {
        setSelectContranctId(id);
    }

    const updateฺBalance = (data: any) => {
        const _payment = {
            ...payment,
            paymentType: data.paymentType,
        }
        setBalance(data.amount);
        setPayment(_payment);
    }

    const updatePayment = (data: any) => {
        const _payment = {
            ...payment,
            depositForwardAmt: data.depositForwardAmt,
            totalAmt: data.paymentAmt,
            balanceAmt: data.balanceAmt,
            penaltyAmt: data.penaltyAmt,
            remainAmt: data.remainAmt,
            remainType: data.remainType,
            isFinish: true,
        }

        console.log("payment", _payment);
        setPayment(_payment);
    }

    const saveDataToDB = () => {
        const _payment = {
            ...payment,
            contractId: contract.id,
            contractNo: contract.contractNo,
            contractDebitId: contractDebit.id,
            month: contractDebit.month,
            interestRevenues: contractDebit.interestRevenues,
            princPepaid: contractDebit.princPepaid,
        }

        console.log("save payment", _payment)
        paymentService.addPayment(_payment).then((res) => {
            if (res.status === 200) history.goBack();
        }).catch((err) => {
            console.log(err);
        });
    }

    const next = () => {
        setCurrentState((prev) => prev + 1);
    };
    const prev = () => {
        setCurrentState((prev) => prev - 1);
    };
    const changeSteps = (curr: number) => {
        setCurrentState(curr);
    };

    const steps = [
        {
            title: "เลือกสัญญา",
            content: <FindContract next={next} updateSelectContract={updateSelectContract} />,
        },
        {
            title: "รายละเอียด",
            content: (<ContractDetail 
                        next={next} 
                        application={application} 
                        address={address} 
                        contractDebit={contractDebit}
                        updateฺBalance={updateฺBalance}
                    />),
        },
        {
            title: "รับชำระ",
            content: (<PaymentDetail 
                        contract={contract} 
                        contractDebit={contractDebit} 
                        deposit={deposit} 
                        balance={balance}
                        updatePayment={updatePayment}
                    />),
        },
    ];

    return (
        <div>
            <PageHeader title="สร้าง ใบรับชำระ">
                <Button
                size="large"
                icon={<CloseCircleOutlined />}
                onClick={() => history.goBack()}
                >
                    Cancel
                </Button>
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ marginLeft: "30px", width: "100%", padding: "40px" }}>
                    <Steps
                        current={currentState}
                        direction="horizontal"
                        onChange={(e) => changeSteps(e)}
                        style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        }}
                    >
                        {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div style={{ width: "100%", paddingTop: "10px" }}>
                        {steps[currentState].content}
                    </div>
                </Panel>

                {currentState > 0 ?
                    <Panel style={{marginLeft:"10px"}}>
                        <div style={{ display: "flex", width: "300px" }}>
                            <ShowContract 
                                contract={contract} 
                                contractDebit={contractDebit} 
                                campaign={campaign} 
                                campaignHead={campaignHead} 
                                deposit={deposit}
                            />                
                        </div>
                    </Panel>
                : ""}
            </div>
        </div>
    )
}

export default NewPayment
