import React, { useEffect, useState } from 'react'
import {Table} from 'antd'
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/th';

import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';

import classes from "src/views/application/ListApplication.module.css";

const dateFormat = "DD/MM/YYYY";

const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: Contract, index: number) => (
        index+1
      ),
    },
    {
      title: "เลขที่สัญญา",
      dataIndex: "contractNo",
      key: "contractNo",
      render: (value: string, item: Contract, index: number) => (
        <Link to={`/app/contract/view/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "ผู้ขอสินเชื่อ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "เลขที่ใบคำขอสินเชื่อ",
      dataIndex: "applicationNo",
      key: "applicationNo",
    },
    {
      title: "วันที่อนุมัติ",
      dataIndex: "approveDate",
      key: "approveDate",
      render: (value: string) => value ? moment(value).format(dateFormat) : "",
    },
    {
      title: "วันที่สัญญา",
      dataIndex: "contractDate",
      key: "contractDate",
      render: (value: string) => value ? moment(value).format(dateFormat) : "",
    },
    {
      title: "วันที่โอนเงิน",
      dataIndex: "payDate",
      key: "payDate",
      render: (value: string) => value ? moment(value).format(dateFormat) : "",
    },
  ];
  
const ListContract: React.FC<Contract> = () => {
    const [contracts, setContracts] = useState<Contract[]>([]);

    useEffect(() => {
        (async () => {
            const res = await contractService.getContracts()
            setContracts(res.data);
        })();
    }, []);

    useEffect(() => {}, [contracts]);

    return (
        <div>
            <PageHeader title="Contract List"/>
            <Content className="app-page-content">
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={contracts}
                  size="small"
                  rowKey={(item: Contract) => item.contractNo}
                />
              </div>
            </Content>
        </div>
    )
}

export default ListContract
