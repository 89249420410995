import { Button, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined } from "@ant-design/icons";

import applicationService from "src/services/application/applicationService"
import { Application } from "src/domain/application/applicationType"
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";

const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (value: string, item: Application, index: number) => (
      index+1
    ),
  },
  {
    title: "Application No",
    dataIndex: "applicationNo",
    key: "applicationNo",
    //   width: 90,
    render: (value: string, item: Application, index: number) => (
      <Link to={`/app/application/view/${item.id}`}>{value}</Link>
    ),
  },
  {
    title: "Date",
    dataIndex: "applicationDate",
    key: "applicationDate",
    render: (value: string) => moment(value).format(dateFormat),
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "nameThai",
    key: "nameThai",
  },
  {
    title: "วงเงินกู้",
    dataIndex: "loanAmt",
    key: "loanAmt",
    align: "right" as "right",
    render: (value: number) =>
      Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
  },
  {
    title: "จำนวนงวด",
    dataIndex: "loanInstallment",
    key: "loanInstallment",
    align: "right" as "right",
  },
];

const ListApplication: React.FC<Application> = () => {
  const history = useHistory();
  const [applications, setApplications] = useState<Application[]>([]);
  const [appWaiting, setAppWaiting] = useState<Application[]>([]);
  const [appApproved, setAppApproved] = useState<Application[]>([]);
  const [appDisapproval, setAppDisapproval] = useState<Application[]>([]);
  const [appCancel, setAppCancel] = useState<Application[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(30)
      setApplications(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(31)
      setAppWaiting(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(33)
      setAppApproved(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(32)
      setAppDisapproval(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(40)
      setAppCancel(res.data);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {}, [applications]);

  const onChangeTabs = (value: any) => {

  }
 
  return (
    <div>
      <PageHeader title="Application List">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/application/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
          <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
            <TabPane tab="สร้าง" key="1">
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={applications}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                />
              </div>
            </TabPane>
            <TabPane tab="รออนุมัติ" key="2">
              <Table
                  columns={columns}
                  dataSource={appWaiting}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                />
            </TabPane>
            <TabPane tab="อนุมัติแล้ว" key="3">
              <Table
                columns={columns}
                dataSource={appApproved}
                size="small"
                rowKey={(item: Application) => item.applicationNo}
              />
            </TabPane>

            <TabPane tab="ไม่อนุมัติ" key="4">
              <Table
                columns={columns}
                dataSource={appDisapproval}
                size="small"
                rowKey={(item: Application) => item.applicationNo}
              />
            </TabPane>

            <TabPane tab="ยกเลิก" key="5">
              <Table
                columns={columns}
                dataSource={appCancel}
                size="small"
                rowKey={(item: Application) => item.applicationNo}
              />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  );
};

export default ListApplication
