import React, { useEffect, useState } from 'react'
import {Button, Form, Input, message, Radio, Space, Table} from 'antd'
import { SearchOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';

import moment from 'moment';
import 'moment/locale/th';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    next: () => void;
    updateSelectContract: any;
}

const FindContract : React.FC<IProps> = (props) => {
    const {next, updateSelectContract} = props;
    const [form] = Form.useForm();
    const [contracts, setContrancts] = useState<Contract[]>([]);
    const [selectContId, setSelectContId] = useState<number>(0);

    useEffect(() => {},[contracts]);

    const findByParam = () => {
        const _contractNo = form.getFieldValue("contractNo")
        const _idCard = form.getFieldValue("idCard")

        if(_contractNo || _idCard) {
            contractService.getByParam(
                _contractNo === undefined ? "" : _contractNo, 
                _idCard === undefined ? "" : _idCard).then(res => {
                    console.log("data", res.data)
                if(res.status === 200) setContrancts(res.data);
            })
        } else {
            message.error('กรุณาระบุ เลขที่สัญญา หรือเลขที่บัตร อย่างใดอย่างหนึ่ง ก่อนกดค้นหา!!');
        }
    }

    const onChangeContract = (values: any) => {
        console.log("onChangeContract", values)
    }

    const onFinish = (values: any) => {
        console.log("onFinish id", selectContId)
        if(selectContId === 0) {
            message.error('กรุณาเลือกรายการที่ต้องการชำระเงิน ก่อนกด Next!!');
        } else {
            console.log("select id", selectContId)
            updateSelectContract(selectContId);
            next();
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> FindContract:", errorInfo);
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: Contract, index: number) => (
                index+1
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
            key: "contractNo",
        },
        {
            title: "เลขที่บัตรประชาชน",
            dataIndex: "applicationNo",
            key: "applicationNo",
        },
        {
            title: "ชื่อ-สกุล",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "วันที่สัญญา",
            dataIndex: "contractDate",
            key: "contractDate",
            render: (value: string) => value ? moment(value).format(dateFormat) : "",
        },
    ];

    return (
        <div>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{marginLeft: "20px",width: "40%"}}>
                        <Form.Item
                            label="เลขที่สัญญา"
                            name="contractNo"
                            key="contractNo"
                        >
                            <Input/>
                        </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px",width: "40%"}}>
                        <Form.Item
                            label="เลขที่บัตรประชาชน"
                            name="idCard"
                            key="idCard"
                        >
                            <Input/>
                        </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px",width: "10%"}}>
                    <Form.Item
                    label=" "

>
    <Button 
        icon={<SearchOutlined/>}
        size="middle"
        onClick={findByParam}
    >
        ค้นหา
    </Button>
</Form.Item>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", width:"100%", marginLeft:"45%"}}>
                    
                </div>
                <Table
                    dataSource={contracts}
                    columns={columns}
                    
                    rowSelection={{
                        type: 'radio',
                        ...onChangeContract                         
                    }}
                    rowKey={(item: Contract) => item.id}
                    onChange={onChangeContract}
                    onRow={(record, rowIndex) => {
                            return {
                                onChange: event => {
                                    setSelectContId(record.id)
                                    console.log("onRow change", record)
                                },
                            };
                        }}
                />

                <Form.Item wrapperCol={{ offset: 22, span: 2 }}>
                    <Button
                        size="small"
                        type="primary"
                        name="next"
                        icon={<RightCircleOutlined />}
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Next
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default FindContract
