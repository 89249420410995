import { Descriptions, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { Address } from 'src/domain/application/addressType';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    application : any;
    addressCurrent : any;
}

const ViewPersonalApplication : React.FC<IProps> = (props) => {
    const {application, addressCurrent} = props;
    const history = useHistory();

    console.log("view:",application);
    return (
        <>
           {/* <PageHeader
                title={"View personal"}
                onClose={() => history.push("/app/application")}
            >
            </PageHeader>  */}
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        // title="Personal Information"
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="คำนำหน้าชื่อ(ไทย) :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.prenameThaiName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="คำนำหน้าชื่อ(อังกฤษ) :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.prenameEngName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อภาษาไทย :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.nameThai}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อภาษาอังกฤษ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.nameEng}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="วันเกิด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.birthday ? moment(application.birthday).format(dateFormat) : ""}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="สัญชาติ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.nationality}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เลขบัตรประชาชน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.idCard}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="สถานภาพ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.marriageStatusName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="สถานะของการอยู่อาศัย :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.livingStatusName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อหมู่บ้าน/อาคารชุด :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.building}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เลขที่ห้องชั้น"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.roomNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="บ้านเลขที่"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.address} 
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="หมู่"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.moo} 
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ซอย :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.soi}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ถนน :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressCurrent.road}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ที่อยู่ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {"ตำบล"+addressCurrent.tambonName + " " + addressCurrent.amphurName + " จังหวัด" + addressCurrent.provinceName + " " + addressCurrent.zipcode}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="โทรศัพท์มือถือ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.mobile}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เบอร์โทรศัพท์(สำรอง) :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.phone}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="อีเมล :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.email}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="LINE ID :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.lineId}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="FACEBOOK :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.facebook}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="การรับเอกสาร :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.decitionDocChannalName}
                        </Descriptions.Item>

                    </Descriptions>
                </div>
            </Content>
        </>
    )
}

export default ViewPersonalApplication
