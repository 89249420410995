import React, { useEffect, useState } from 'react'
import {BackTop, Button, Col, Descriptions, Form, InputNumber, Radio, Row, Space, Typography, } from 'antd'
import {RightCircleOutlined, UpCircleOutlined} from '@ant-design/icons'
import Panel from 'src/components/container/Panel'
import { Contract } from 'src/domain/contract/contractType';
import { Address } from 'src/domain/application/addressType';
import { Application } from 'src/domain/application/applicationType';
import { ContractDebit } from 'src/domain/contract/contractDebitType';

const { Title, Paragraph, Text } = Typography;

const color_bg_readonly = "#EAECEE"

interface IProps {
    next: () => void;
    application: Application;
    address: Address;
    contractDebit: ContractDebit;
    updateฺBalance: any;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

const ContractDetail : React.FC<IProps> = (props) => {
    const {next, application, address, contractDebit, updateฺBalance} = props;
    const [form] = Form.useForm();

    const [counter, setCounter] = useState<number>(1);
    const [disableMinus, setDisableMinus] = useState<boolean>(false);
    const [disablePlus, setDisablePlus] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);

    const options_paytypes = [{label:'เงินสด',value:'CASH'}, {label:'QR',value:'QR'}]

    useEffect(() => {
        setDisableMinus(counter <= 1);
        setDisablePlus(counter >= 1);
        // if (onChange !== undefined) onChange(counter);
        if(contractDebit && contractDebit.installment) setTotalAmount(counter * contractDebit.installment);
    }, [counter]);

    useEffect(() => {
        if(contractDebit) {
            setTotalAmount(counter * contractDebit.installment);
        }
        form.setFieldsValue({
            month: contractDebit.month,
            installment_amt: contractDebit.installment,
            total_amt: totalAmount,
        });
    },[contractDebit, totalAmount]);

    const minus = () => {
        setCounter((x) => x - 1);
    };
    
    const plus = () => {
        setCounter((x) => x + 1);
    };

    const onFinish = (values: any) => {
        console.log("contract", values)
        let x: any = {
            paymentType: values.pay_type,
            amount: totalAmount,
        }
        updateฺBalance(x);
        next();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> ContractDetail:", errorInfo);
    }

    return (
        <div>
            <Form
                {...layout}
                form={form}
                name="basic"
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Panel>
                    <Typography>
                        <Title level={3}>ข้อมูลส่วนบุคล</Title>
                    </Typography>
                    <Descriptions
                        column={2}
                        size="middle"
                        labelStyle={{ width: 120, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            span={2}
                            label="ชื่อ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.prenameThaiName + " " + application.nameThai}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เลขที่บัตร :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.idCard}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เบอร์โทร :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.mobile}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label="ที่อยู่ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {address.addressText}
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>
                
                <Panel style={{marginTop: 10,}}>
                    <Typography>
                        <Title level={3}>ข้อมูลงวด</Title>
                    </Typography>
                    <Form.Item
                        label="จำนวนงวดที่จ่าย"
                        name="installment_pay"
                        key="installment_pay"
                    >
                        <Row justify="center" >
                            <Col span={22} style={{ textAlign: "center", display: "flex" }}>
                        {/* <InputNumber 
                            defaultValue={1}
                            precision={0}
                            style={{ width: 150}}
                        /> */}
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    danger
                                    onClick={() => minus()}
                                    disabled={disableMinus}
                                >
                                    -
                                </Button>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                    <Typography.Title level={3}>{counter}</Typography.Title>
                                </div>
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    onClick={() => plus()}
                                    disabled={disablePlus}
                                >
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item
                        label="งวดที่"
                        name="month"
                        key="month"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={0}
                        />
                    </Form.Item>
                    <Form.Item
                        label="ค่างวด"
                        name="installment_amt"
                        key="installment_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ค่าปรับ"
                        name="penalty_amt"
                        key="penalty_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ยอดหนี้สุทธิ"
                        name="total_amt"
                        key="total_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                </Panel>

                <Panel style={{marginTop: 10, alignItems:"center"}}>
                    <Typography>
                        <Title level={3}>ประเภทการชำระ</Title>
                    </Typography>
                    <Form.Item
                        label="การรับเงิน"
                        name="pay_type"
                        rules={[
                            { required: true, message: "กรุณาเลือกประเภทการรับเงิน!" },
                        ]}
                    >
                        <Radio.Group 
                                options={options_paytypes}
                                optionType="button"
                                buttonStyle="solid"
                                size="middle"
                                // value={selectLoans}
                                // defaultValue={selectLoans}
                                // onChange={onChangeLoans}
                            >
                            </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 18}}>
                        <Button
                            size="small"
                            type="primary"
                            name="next"
                            icon={<RightCircleOutlined />}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Next
                        </Button>
                    </Form.Item>
                </Panel>
            </Form>
        </div>
    )
}

export default ContractDetail
