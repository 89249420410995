import { Button, Col, Descriptions, Popconfirm, Row, Space, Switch, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { PlusOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';

import { Campaign } from 'src/domain/master/CampaignType';
import campaignService from 'src/services/master/campaignService';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import { Link } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';

const dateFormat = 'DD/MM/YYYY';

interface IParams {
    id: string;
}

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "รหัส",
        dataIndex: "campaignHeadNo",
        key: "campaignHeadNo",
        render: (value: string, item: CampaignHead, index: number) => (
            <Link to={`/app/campaign/view/campaignHead/${item.id}`}>{value}</Link>
        ),
    },
    {
        title: "วงเงินกู้",
        dataIndex: "loanAmt",
        key: "loanAmt",
        align: 'right' as 'right',
        render : (value: string, item: CampaignHead, index: number) => (
            <Link to={`/app/campaign/view/campaignHead/${item.id}`}>{Number(Number(value).toFixed(2)).toLocaleString('th-TH')}</Link>
        )
    },
    {
        title: "จำนวนงวด",
        dataIndex: "tenor",
        key: "tenor",
        align: 'right' as 'right',
    },
    {
        title: "ดอกเบี้ย",
        dataIndex: "effInterestAnnum",
        key: "effInterestAnnum",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "จ่ายต่องวด",
        dataIndex: "installment",
        key: "installment",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
]

const ViewCampaign : React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _campaignId = Number(props.match.params.id);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [campaignHead, setCampaignHead] = useState<CampaignHead[]>([] as CampaignHead[]);

    useEffect(() => {
        campaignService.getCampaignById(_campaignId).then(res=>{
            setCampaign(res.data);
        });
    }, []);

    useEffect(() => {
        campaignHeadService.getCampaignHeadByCampId(_campaignId).then(res=>{
            setCampaignHead(res.data);
        });
    }, [campaign]);

    useEffect(() => {
        (async () => {
            if(_campaignId) {
                const res = await campaignHeadService.getCampaignHeadByCampId(_campaignId);
                setCampaignHead(res.data);
            }
        })();
    }, [campaign]);

    const onClickDelete = () => {
        const _campaign : Campaign = {
            ...campaign,
            useFlg : -1,
        }
        campaignService.editCampaign(_campaign).then(res => {
            if(res.status === 200)
            history.goBack();
        }).catch(err => {
            console.log(err)
        });
    }

    const cancel = () => {

    }

    return (
        <div>
            <PageHeader title="NIMCAP - กำหนด CAMPAIGN" onClose={() => history.goBack()}>
                <Space>
                    {campaign.useFlg === 0 ?
                    <Button
                        size="large"
                        icon={<FormOutlined />}
                        onClick={() => history.push("/app/campaign/edit/" + _campaignId)}
                        >
                        แก้ไข
                    </Button>
                    :""
                    }
                    {campaign.useFlg === 0 ?
                    <Popconfirm
                        title="ต้องการลบข้อมูล Campaign นี้ใช่หรือไม่?"
                        onConfirm={onClickDelete}
                        onCancel={cancel}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                        <Button
                            size="large"
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger
                            >
                            ลบ
                        </Button>
                    </Popconfirm>
                    :""
                    }
                    {campaign.useFlg === -1 ?
                        <Switch defaultChecked={false} disabled unCheckedChildren={"Inactive"} />
                    :""
                    }
                </Space>
            </PageHeader>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        // title="Personal Information"
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="CAMPAIGN CODE :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {campaign.campaignCode}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="CAMPAIGN :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {campaign.campaignName}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่เริ่มต้น :"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {moment(campaign.startDate).format(dateFormat)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่สิ้นสุด :"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {moment(campaign.endDate).format(dateFormat)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="หมายเหตุ :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {campaign.remark}
                        </Descriptions.Item>
                    </Descriptions>

                    <Row>
                        <Col style={{ marginTop: "20px" }}>
                            {campaign.useFlg === 0 ?
                            <Button
                                size="small"
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => history.push("/app/campaign/new/campaignHead/"+_campaignId)}
                                >
                                New
                            </Button>
                            :""
                            }
                        </Col>
                    </Row>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                        layout="vertical"
                    >
                        <Descriptions.Item
                            label="รายละเอียด CAMPAIGN"
                            span={2}
                        >
                            <Table
                                columns={columns}
                                dataSource={campaignHead}
                                size="small"
                                rowKey={(item: CampaignHead) => item.id}
                                pagination={false}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
        </div>
    )
}

export default ViewCampaign
