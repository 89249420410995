import http from "../../lib/http";
import configs from "src/constants/config";

const url = "http://localhost:8080/payment";

const addPayment = (payment : any) => {
    return http.post(url, payment);
} 

const getPaymentById = (id: number) => {
    return http.get(url+"/"+id);
}

const getPaymentByNo = (no: string) => {
    return http.get(url+"/byNo?paymentNo="+no);
}

const getByStation = (station: string) => {
    return http.get(url + "/byStation?station="+station);
}

export default {
    addPayment,
    getPaymentById,
    getPaymentByNo,
    getByStation,
}