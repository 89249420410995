import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, UnlockTwoTone, LockTwoTone, LockFilled } from "@ant-design/icons";
import { Button, Modal, Table } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router';
import { Campaign } from 'src/domain/master/CampaignType';
import campaignService from 'src/services/master/campaignService';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import NewCampaign from './NewCampaign';

import classes from "src/views/application/ListApplication.module.css";

const dateFormat = 'DD/MM/YYYY';
const columns = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: Campaign, index: number) => (
            index+1
        ),
    },
    {
        title: "Code",
        dataIndex: "campaignCode",
        key: "campaignCode",
        render: (value: string, item: Campaign, index: number) => (
            <Link to={`/app/campaign/view/${item.id}`}>{value}</Link>
        ),
    },
    {
        title: "CAMPAIGN",
        dataIndex: "campaignName",
        key: "campaignName",
        rendor : (value: string, item: Campaign) => (
            item.useFlg === 0 ? 
                value
                :
                <span style={{backgroundColor:"red"}}>value</span>
        )
    },
    {
        title: "วันที่เริ่มต้น",
        dataIndex: "startDate",
        key: "startDate",
        align: 'center' as 'center',
        render : (value : string) => (
            moment(value).format(dateFormat)
        )
    },
    {
        title: "วันที่สิ้นสุด",
        dataIndex: "endDate",
        key: "endDate",
        align: 'center' as 'center',
        render : (value : string) => (
            moment(value).format(dateFormat)
        )
    },
    {
        title: "สถานะ",
        dataIndex: "useFlg",
        key: "useFlg",
        align: 'center' as 'center',
        render : (value : number) => (
            value === 0 ? <UnlockTwoTone /> : <LockFilled>ยกเลิก</LockFilled>
        )
    },
]

const ListCampaign: React.FC<Campaign> = () => {
    const history = useHistory();
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    useEffect(() => {
        (async () => {
          setLoading(true);
          const res = await campaignService.getCampaignAll();
          setCampaigns(res.data);
          setLoading(false);
        })();
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setIsModalVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <PageHeader title="Campaign List">
                <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/campaign/new")}
                // onClick={showModal}
                >
                New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div className={classes.dataPanel}>
                    <Table
                        columns={columns}
                        dataSource={campaigns}
                        size="small"
                        rowKey={(item: Campaign) => item.campaignCode}
                        // rowClassName={(record, index) => index % 2 === 0 ? 'backgroundColor: #ffffff' :  'backgroundColor: #fbfbfb'}
                    />
                </div>
            </Content> 

            <Modal 
                title="ADD CAMPAIGN" 
                visible={isModalVisible} 
                onOk={handleOk} 
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width="60%"
            >
                    <NewCampaign handleOk={handleOk}/>
            </Modal>
        </div>
    )
}

export default ListCampaign
