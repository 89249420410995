import React, { useEffect, useState } from 'react'
import {Button, Descriptions, Form, InputNumber, Radio, Typography, } from 'antd'
import {SaveOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel'
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import { Deposit } from 'src/domain/payment/depositType';
import { Contract } from 'src/domain/contract/contractType';

const { Title, Paragraph, Text } = Typography;
const RadioButton = Radio.Button;

const color_bg_readonly = "#EAECEE"

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IProps {
    contract: Contract;
    contractDebit: ContractDebit;
    deposit: Deposit;
    balance: number;
    updatePayment: any;
}

const options_change_deposit_types = [{label:'ทอน', value:'CHANGE'},{label:'ฝาก', value:'DEPOSIT'}]

const PaymentDetail : React.FC<IProps> = (props) => {
    const {contract, contractDebit, deposit, balance, updatePayment} = props;
    const [form] = Form.useForm();

    const [totalAmount, setTotalAmount] = useState<number>(0);

    useEffect(() => {
        form.setFieldsValue({
            deposit_amt: deposit ? deposit.totalAmt : 0,
            balance_amt: balance,
            penalty_amt: 0,
            installment_amt: contractDebit.installment,
            total_amt: totalAmount,
        });

        // if(totalAmount < 0) {
        //     form.getFieldValue("change_deposit_type")
        // }
    },[totalAmount]);

    const onChangePay = (value: any) => {
        calculateTotalAmount(value);
    }

    const calculateTotalAmount = (value: any) => {
        const _deposit_amt = deposit ? Number(Number(deposit.totalAmt).toFixed(2))  : 0;
        const _balance = balance ? Number(Number(balance).toFixed(2))  : 0;
        const _penalty_amt = 0;

        const _total_amt = Number(Number((_deposit_amt+value) - _balance - _penalty_amt).toFixed(2));
        console.log("_total_amt", _total_amt)
        setTotalAmount(_total_amt);
    }

    const onFinish = async (values: any) => {

        let x : any = {
            depositForwardAmt: values.deposit_amt,
            paymentAmt: values.pay_amt,
            balanceAmt: values.balance_amt,
            penaltyAmt: values.penalty_amt,
            installmentAmt: values.installment_amt,
            remainAmt: values.total_amt,
            remainType: values.change_deposit_type,
        }

        updatePayment(x);
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> PaymentDetail:", errorInfo);
    }

    return (
        <div>
            <Form
                {...layout}
                form={form}
                name="basic"
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Panel>
                    <Typography>
                        <Title level={2}>รับชำระ</Title>
                    </Typography>
                    <Form.Item
                        label="รับฝากคงเหลือ"
                        name="deposit_amt"
                        key="deposit_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="จำนวนเงินรับชำระ"
                        name="pay_amt"
                        key="pay_amt"
                    >
                        <InputNumber
                            style={{ width: 150, textAlign: "right",}} 
                            precision={2}
                            onChange={onChangePay}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <hr/>
                    <Form.Item
                        label="ยอดหนี้สุทธิ"
                        name="balance_amt"
                        key="balance_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ตัดค่าปรับ"
                        name="penalty_amt"
                        key="penalty_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ตัดค่างวด"
                        name="installment_amt"
                        key="installment_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <hr/>
                    <Form.Item
                        label="คงเหลือ"
                        name="total_amt"
                        key="total_amt"
                    >
                        <InputNumber 
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}} 
                            precision={2}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ทอน/ฝาก"
                        name="change_deposit_type"
                        rules={[
                            { required: true, message: "กรุณาเลือกข้อมูล!" },
                        ]}
                    >
                        <Radio.Group>
                            {options_change_deposit_types.map((opt_value) => (
                                <RadioButton
                                    key={opt_value.value} 
                                    value={opt_value.value} 
                                    disabled={(totalAmount < 0 && opt_value.value === 'CHANGE') ? true : false}
                                >
                                    {opt_value.label}
                                </RadioButton>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 20, span: 2 }}>
                        <Button
                            danger
                            size="small"
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Panel>
            </Form>
        </div>
    )
}

export default PaymentDetail
