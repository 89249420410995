import { Descriptions, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel'
import { Payment } from 'src/domain/payment/paymentType';

import moment from 'moment';
import 'moment/locale/th';
import { PaymentDetail } from 'src/domain/payment/paymentDetailType';
import depositService from 'src/services/payment/depositService';
import { DepositDetail } from 'src/domain/payment/depositDetailType';
import { Application } from 'src/domain/application/applicationType';

const { Title, Paragraph, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

interface IProps {
    payment : Payment;
    paymentDetails: PaymentDetail[];
    itemDeposit: PaymentDetail;
    depositDetails: DepositDetail[];
    application: Application;
}

const ViewPaymentDetail : React.FC<IProps> = (props) => {
    const {payment, paymentDetails, itemDeposit, depositDetails, application} = props;

    return (
        <div>
            <Panel>
                <Typography>
                    <Title level={3}>ข้อมูลส่วนบุคล</Title>
                </Typography>
                <Descriptions
                    column={2}
                    size="middle"
                    labelStyle={{ width: 120, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        span={2}
                        label="ชื่อ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {application.prenameThaiName + " " + application.nameThai}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="เลขที่บัตร :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {application.idCard}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="เบอร์โทร :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {application.mobile}
                    </Descriptions.Item>
                </Descriptions>
            </Panel>
            <Panel style={{marginTop: 10,}}>
                <Typography>
                    <Title level={3}>ข้อมูลการรับชำระ</Title>
                </Typography>
                <Descriptions
                    column={1}
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        label="วันที่รับชำระ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        <label style={{fontWeight: "bold", fontSize:"medium"}}>{moment(payment.paymentDate).format(dateFormat)}</label>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="การรับเงิน :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {payment.paymentType}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="งวดที่ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {paymentDetails.length > 0 ? paymentDetails[0].month : 0 }
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="จำนวนเงินรับชำระ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {payment.totalAmt ? Number(Number(payment.totalAmt).toFixed(2)).toLocaleString('uth-TH') : 0}
                    </Descriptions.Item>

                    {itemDeposit.amt ?
                        <Descriptions.Item
                            label="เงินฝาก ยกมา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {itemDeposit ? Number(Number(itemDeposit.amt).toFixed(2)).toLocaleString('uth-TH') : 0}
                        </Descriptions.Item>
                    :""}
                </Descriptions>
                <hr/>
                <Descriptions
                    column={1}
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                    bordered
                >
                    {paymentDetails ? paymentDetails.map((item) => (
                        <Descriptions.Item
                            label={item.paymentItemName + " :"}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                            key={item.id}
                        >
                            {item.amt ? Number(Number(item.amt).toFixed(2)).toLocaleString('uth-TH') : 0}
                        </Descriptions.Item>
                    )) : ""}

                    {depositDetails ? depositDetails.map((item) => (
                        <Descriptions.Item
                        label="เงินฝาก :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {item.depositAmt ? Number(Number(item.depositAmt).toFixed(2)).toLocaleString('uth-TH') : 0}
                    </Descriptions.Item>
                    )) : ""}
                </Descriptions>
            </Panel>
        </div>
    )
}

export default ViewPaymentDetail
