import { CreateUserCommand } from "../../store/user/action";
import http from "../../lib/http";

const url = "http://localhost:8080/users";

const createUser = (command: CreateUserCommand) => {
  return http.post(url + "/create", command);
};

export default {
  createUser,
};
