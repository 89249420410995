import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "src/layouts/AppLayout";
import ProductForm from "./sample/ProductForm";
import ProductList from "./sample/ProductList";
import Dashboard from "./Dashboard";
import ListUser from "./users/ListUser";
import NewUser from "./users/NewUser";
import ViewUser from "./users/ViewUser";
import EditUser from "./users/EditUser";
import ListPost from "./posts/ListPosts";
import ViewPost from "./posts/ViewPost";
import EditPost from "./posts/EditPost";
import NewPost from "./posts/NewPost";
import CountList from "./counter";
import Regex from "./Regex";
import ListTeacher from "./teacher/ListTeacher";
import NewTeacher from "./teacher/NewTeacher";
import ViewTeacher from "./teacher/ViewTeacher";
import EditTeacher from "./teacher/EditTeacher";
import StudentList from "./student/StudentList";
import NewStudent from "./student/NewStudent";
import EditStudent from "./student/EditStudent";
import ViewStudent from "./student/ViewStudent";

import ListSubject from "./subject/ListSubject";
import NewSubject from "./subject/NewSubject";
import ViewSubject from "./subject/ViewSubject";
import EditSubject from "./subject/EditSubject";

import ListOfficer from "./officer/ListOfficer";
import ViewOfficer from "./officer/ViewOfficer";
import EditOfficer from "./officer/EditOfficer";
import NewOfficer from "./officer/NewOfficer";
import ModernAppLayout from "../layouts/ModernAppLayout";
import SecurityModule from "./security/SecurityModule";

import ListApplication from "./application/ListApplication";
import NewApplication from "./application/NewApplication";
import ViewApplication from "./application/ViewApplication";

import ListBranch from "./master/master-branch/ListBranch";
import ManageBranchForm from "./master/master-branch/ManageBranchForm";
import ListBank from "./master/master-bank/ListBank";
import ManageBankForm from "./master/master-bank/ManageBankForm";
import ListClass from "./master/master-class/ListClass";
import ManageClassForm from "./master/master-class/ManageClassForm";
import ManageClassDetailForm from "./master/master-class/ManageClassDetailForm";

import ListCampaign from "./campaign/ListCampaign";
import NewCampaign from "./campaign/NewCampaign";
import CampaignHeadForm from "./campaign/CampaignHeadForm";
import ViewCampaign from "./campaign/ViewCampaign";
import ViewCampaignHead from "./campaign/ViewCampaignHead";
import NewCampaignHead from "./campaign/NewCampaignHead";
import EditCampaign from "./campaign/EditCampaign";
import EditApplication from "./application/EditApplication";
import EditCampaignHead from "./campaign/EditCampaignHead";
import ListContract from "./contract/ListContract";
import ViewContract from "./contract/ViewContract";
import ContractForm from "./contract/ContractForm";
import ListPayment from "./payment/ListPayment";
import NewPayment from "./payment/NewPayment";
import ViewPayment from "./payment/ViewPayment";
import ListAccBook from "./accbook/ListAccBook";
import NewAccBook from "./accbook/NewAccBook";
import ListAccount from "./account/ListAccount";
import NewAccount from "./account/NewAccount";
import ViewAccount from "./account/ViewAccount";
import EditAccount from "./account/EditAccount";
import ViewAccBook from "./accbook/ViewAccBook";

import ListReport from "./report/ListReport";
import ManageReport from "./report/ManageReport";

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  // console.log(props);
  return (
    // <AppLa?yout>
    <ModernAppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/* product s*/}
        <Route exact path="/app/products/view/:id" component={ProductForm} />
        <Route exact path="/app/products/edit/:id" component={ProductForm} />
        <Route exact path="/app/products/new" component={ProductForm} />
        <Route exact path="/app/products/list" component={ProductList} />
        <Redirect path="/app/products" to="/app/products/list" />

        {/* user */}
        <Route exact path="/app/users/list" component={ListUser} />
        <Route exact path="/app/users/new" component={NewUser} />
        <Route exact path="/app/users/view/:id" component={ViewUser} />
        <Route exact path="/app/users/edit/:id" component={EditUser} />
        <Redirect path="/app/users" to="/app/users/list" />

        {/* campaign */}
        <Route exact path="/app/campaign/list" component={ListCampaign} />
        <Route exact path="/app/campaign/new" component={NewCampaign} />
        <Route exact path="/app/campaign/view/:id" component={ViewCampaign} />
        <Route exact path="/app/campaign/edit/:id" component={EditCampaign} />
        <Route exact path="/app/campaign/view/campaignHead/:headId" component={ViewCampaignHead} />
        <Route exact path="/app/campaign/new/campaignHead/:id" component={NewCampaignHead} />
        <Route exact path="/app/campaign/edit/campaignHead/:id/:campId" component={EditCampaignHead} />
        <Redirect path="/app/campaign" to="/app/campaign/list" />

        {/* application */}
        <Route exact path="/app/application/list" component={ListApplication} />
        <Route exact path="/app/application/new" component={NewApplication} />
        <Route exact path="/app/application/view/:id" component={ViewApplication} />
        <Route exact path="/app/application/edit/:id" component={EditApplication} />
        <Redirect path="/app/application" to="/app/application/list" />

        {/* contract */}
        <Route exact path="/app/contract/list" component={ListContract} />
        <Route exact path="/app/contract/view/:id" component={ViewContract} />
        <Route exact path="/app/contract/edit/:id" component={ContractForm} />
        <Redirect path="/app/contract" to="/app/contract/list" />

        {/* payment */}
        <Route exact path="/app/payment/list" component={ListPayment} />
        <Route exact path="/app/payment/new" component={NewPayment} />
        <Route exact path="/app/payment/view/:id" component={ViewPayment} />
        <Redirect path="/app/payment" to="/app/payment/list" />

        {/* account */}
        <Route exact path="/app/account/list" component={ListAccount} />
        <Route exact path="/app/account/new" component={NewAccount} />
        <Route exact path="/app/account/view/:id" component={ViewAccount} />
        <Route exact path="/app/account/edit/:id" component={EditAccount} />
        <Redirect path="/app/account" to="/app/account/list" />

        {/* book */}
        <Route exact path="/app/accbook/list" component={ListAccBook} />
        <Route exact path="/app/accbook/new" component={NewAccBook} />
        <Route exact path="/app/accbook/view/:id" component={ViewAccBook} />
        <Redirect path="/app/accbook" to="/app/accbook/list" />

        {/* post */}
        <Route exact path="/app/posts/list" component={ListPost} />
        <Route exact path="/app/posts/new" component={NewPost} />
        <Route exact path="/app/posts/view/:id" component={ViewPost} />
        <Route exact path="/app/posts/edit/:id" component={EditPost} />
        <Redirect path="/app/posts" to="/app/posts/list" />

        {/* counter */}
        <Route exact path="/app/counter" component={CountList} />

        {/* teacher */}
        <Route exact path="/app/teacher/list" component={ListTeacher} />
        <Route exact path="/app/teacher/new" component={NewTeacher} />
        <Route exact path="/app/teacher/view/:id" component={ViewTeacher} />
        <Route exact path="/app/teacher/edit/:id" component={EditTeacher} />
        <Redirect path="/app/teacher" to="/app/teacher/list" />
        {/* student */}
        <Route exact path="/app/student/list" component={StudentList} />
        <Route exact path="/app/student/new" component={NewStudent} />
        <Route exact path="/app/student/edit/:id" component={EditStudent} />
        <Route exact path="/app/student/view/:id" component={ViewStudent} />
        <Redirect path="/app/student" to="/app/student/list" />

        {/* subject */}
        <Route exact path="/app/subject/list" component={ListSubject} />
        <Route exact path="/app/subject/new" component={NewSubject} />
        <Route exact path="/app/subject/view/:id" component={ViewSubject} />
        <Route exact path="/app/subject/edit/:id" component={EditSubject} />
        <Redirect path="/app/subject" to="/app/subject/list" />

        {/* officer */}
        <Route exact path="/app/officers/new" component={NewOfficer} />
        <Route exact path="/app/officers/list" component={ListOfficer} />
        <Route exact path="/app/officers/edit/:id" component={EditOfficer} />
        <Route exact path="/app/officers/view/:id" component={ViewOfficer} />
        <Redirect path="/app/officers" to="/app/officers/list" />

        {/* master */}
        <Route exact path="/app/master/branch/list" component={ListBranch} />
        <Route exact path="/app/master/branch/manage/:id" component={ManageBranchForm} />
        <Route exact path="/app/master/branch/manage" component={ManageBranchForm} />
        <Redirect path="/app/master/branch" to="/app/master/branch/list" />
        {/* <Redirect path="/app/master" to="/app/master/branch" /> */}

        <Route exact path="/app/master/bank/list" component={ListBank} />
        <Route exact path="/app/master/bank/manage/:id" component={ManageBankForm} />
        <Route exact path="/app/master/bank/manage" component={ManageBankForm} />
        <Redirect path="/app/master/bank" to="/app/master/bank/list" />

        <Route exact path="/app/master/class/list" component={ListClass} />
        <Route exact path="/app/master/class/manage/:id" component={ManageClassForm} />
        <Route exact path="/app/master/class/manage" component={ManageClassForm} />
        <Route exact path="/app/master/class/manage/classdetail/:classId/:id" component={ManageClassDetailForm} />
        <Route exact path="/app/master/class/manage/classdetail/:classId" component={ManageClassDetailForm} />
        <Redirect path="/app/master/class" to="/app/master/class/list" />

        {/* report */}
        <Route exact path="/app/report/list" component={ListReport} />
        <Route exact path="/app/report/manage" component={ManageReport} />
        <Redirect path="/app/report" to="/app/report/list" />

        {/* regex */}
        <Route exact path="/app/regex" component={Regex} />

        {/* security */}
        <Route path="/app/security" component={SecurityModule} />

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />
      </Switch>
    </ModernAppLayout>
    // </AppLay?out>
  );
};

export default AppRoute;
