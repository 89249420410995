import { Descriptions, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import campaignService from 'src/services/master/campaignService';
import { CampaignDetail } from 'src/domain/master/CampaignDetailType';

interface IProps {
    application : any;
}

const ViewLoanApplication : React.FC<IProps> = (props) => {
    const {application} = props;
    const history = useHistory();

    const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([] as CampaignDetail[]);

    useEffect(() => {
        (async () => {
            if(application.campaignHeadId) {
                const res = await campaignService.getCampaignDetailByHeadId(application.campaignHeadId);
                setCampaignDetails(res.data);
            }
        })();
    }, []);

    const numberFormat = (value: number) => {
        new Intl.NumberFormat('th-TH', {
            style: 'currency',
            currency: 'THB'
        }).format(value);
    }

    const columns = [
        {
            title: "Mth",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Interest Revenues",
            dataIndex: "interestRevenues",
            key: "interestRevenues",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Princ Repaid",
            dataIndex: "princPepaid",
            key: "princPepaid",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Ending Bal.",
            dataIndex: "endingBal",
            key: "endingBal",
            align: 'right' as 'right',
            render : (value : number) => (
                // Number(Number(value).toFixed(2)).toLocaleString('th-TH')
                new Intl.NumberFormat('th-TH', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }).format(value)
            )
        },
        {
            title: "CF",
            dataIndex: "cf",
            key: "cf",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "% ตัดต้น",
            dataIndex: "principlePer",
            key: "principlePer",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
    ]

    return (
        <div>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        // title="Personal Information"
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="จำนวนเงินที่ขอกู้ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            
                            {Number(Number(application.loanAmt).toFixed(2)).toLocaleString('uth-TH')} <label>บาท</label>
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ระยะเวลาผ่อนชำระ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.loanInstallment} <label>เดือน</label>
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="CAMPAIGN :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.campaignName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อบัญชี :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.accountName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ธนาคาร :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.bankCode} {application.bankName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เลขที่บัญชี :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.bankAccountNo}
                        </Descriptions.Item>
                    </Descriptions>

                    {/* <Descriptions
                        style={{ marginTop: "20px" }}
                        // title="Interest Revenues Calculation"
                        size="middle"
                        // labelStyle={{fontWeight: "bold", backgroundColor:"#ffffff" }}
                        bordered
                        layout="vertical"
                    >
                        <Descriptions.Item
                            label="Interest Revenues Calculation"
                            labelStyle={{fontWeight: "bold" }}
                        > */}
                            <Table
                                columns={columns}
                                dataSource={campaignDetails}
                                size="small"
                                rowKey={(item: CampaignDetail) => item.id}
                                pagination={false}
                                style={{ marginTop: "20px" }}
                            />
                        {/* </Descriptions.Item>
                    </Descriptions> */}
                </div>
            </Content>
        </div>
    )
}

export default ViewLoanApplication
