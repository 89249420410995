import { Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { Payment } from 'src/domain/payment/paymentType';
import PageHeader from 'src/layouts/PageHeader'
import {DeleteOutlined} from '@ant-design/icons'
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import ViewPaymentContract from './ViewPaymentContract';
import ViewPaymentDetail from './ViewPaymentDetail';
import paymentService from 'src/services/payment/paymentService';
import { PaymentDetail } from 'src/domain/payment/paymentDetailType';
import depositService from 'src/services/payment/depositService';
import { DepositDetail } from 'src/domain/payment/depositDetailType';
import { Contract } from 'src/domain/contract/contractType';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import contractService from 'src/services/contract/contractService';
import campaignService from 'src/services/master/campaignService';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { Application } from 'src/domain/application/applicationType';
import applicationService from 'src/services/application/applicationService';

interface IParams {
    id: string;
}

const ViewPayment : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _paymentId = Number(props.match.params.id);
    const history = useHistory();

    const [payment, setPayment] = useState<Payment>({} as Payment);
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
    const [itemDeposit, setItemDeposit] = useState<PaymentDetail>({} as PaymentDetail);
    const [depositDetails, setDepositDetails] = useState<DepositDetail[]>([]);

    const [application, setApplication] = useState<Application>({} as Application);
    const [contract, setContract] = useState<Contract>({} as Contract);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);

    useEffect(() => {
        paymentService.getPaymentById(_paymentId).then(res => {
            const _payment = res.data;
            
            if(_payment) {
                for(let x=0; x<_payment.paymentDetails.length; x++) {
                    const _detail = _payment.paymentDetails[x];
                    if(_detail.paymentItemId === 48) setItemDeposit(_detail);
                    else paymentDetails.push(_detail);
                }
            }
            setPayment(_payment);

            depositService.getByPaymentNoAndType(_payment.paymentNo, "1").then(res => {
                setDepositDetails(res.data);
            });

            contractService.getContractByNo(_payment.contractNo).then(res => {
                const _contract = res.data;
                if(_contract && _contract.campaignId) {
                    campaignService.getCampaignById(_contract.campaignId).then(res => {
                        setCampaign(res.data);
                    });
                }
    
                if(_contract && _contract.campaignHeadId) {
                    campaignHeadService.getCampaignHeadById(_contract.campaignHeadId).then(res => {
                        setCampaignHead(res.data);
                    });
                }

                if(_contract && _contract.applicationNo) {
                    applicationService.getApplicationByNo(_contract.applicationNo).then(res => {
                        setApplication(res.data);
                    })
                }

                setContract(_contract);
            });
            
        });
    }, []);

    const onClickDelete = (e: any) => {

    }

    const cancel = () => {

    }
    return (
        <>
            <PageHeader title={'PAYMENT NO. '+payment.paymentNo} onClose={() => history.push("/app/payment")}>
                {/* <Popconfirm
                        title="ต้องการลบใบรับชำระสินเชื่อใช่หรือไม่?"
                        onConfirm={onClickDelete}
                        onCancel={cancel}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                    <Button
                        size="large"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                        >
                        ลบ
                    </Button>
                </Popconfirm> */}
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginLeft: "30px", width: "100%",}}>
                    <ViewPaymentDetail 
                        payment={payment} 
                        paymentDetails={paymentDetails} 
                        itemDeposit={itemDeposit}
                        depositDetails={depositDetails}
                        application={application}
                    />
                </div>
                <Panel style={{marginLeft:"10px"}}>
                    <div style={{ display: "flex", width: "300px" }}>
                        <ViewPaymentContract 
                            contract={contract} 
                            campaign = {campaign}
                            campaignHead={campaignHead}
                        />
                    </div>
                </Panel>
            </div>
        </>
    )
}

export default ViewPayment
