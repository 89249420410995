import http from "../../lib/http";

const url = "http://localhost:8080/bookCode";

const getBookCodeByBookTypeId = (typeId: number) => {
    return http.get(url+"/ByBookTypeId?bookTypeId="+typeId);
}

const getBookCodeById = (id: number) => {
    return http.get(url+"/"+id);
}

export default {
    getBookCodeByBookTypeId,
    getBookCodeById
}