import http from "../../lib/http";

const url = "http://localhost:8080/payee";

const getPayees = () => {
    return http.get(url);
};

const getPayeesUseOnly = () => {
    return http.get(url+"/allUseOnly");
};

const getPayeeById = (id: number) => {
    return http.get(url+"/"+id);
}

const searchByNameOrTaxOrTel = (text: string) => {
    return http.get(url+"/byNameOrTaxOrTel?text="+text);
}

const addPayee = (payee : any) => {
    return http.post(url, payee);
} 

export default {
    getPayees,
    getPayeesUseOnly,
    getPayeeById,
    searchByNameOrTaxOrTel,
    addPayee
}