import { Affix, Button, Space } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Flex from "src/components/container/Flex";

/* icon */
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  title: string;
  affix?: number;
  onClose?: () => void;
  childrend?: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({
  title,
  onClose,
  children,
  affix = 70,
}) => {
  const history = useHistory();
  const affixStyle = { height: affix };

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div
      className="app-page-header mt-2 mb-4"
      style={{ width: "calc(100% + 5px)" }}
    >
      <Affix offsetTop={0}>
        <Flex
          justifyContent="between"
          alignItems="center"
          style={{
            ...affixStyle,
            backgroundColor: "#F1F2F7",
          }}
        >
          <div style={{ zIndex: 1033 }}>
            <h3 className="mb-1 mr-3 font-weight-semibold">
              {title ? title : "..."}
            </h3>
          </div>

          <div className="d-flex flex-row">
            <div className="action-container">{children}</div>
            <div>
              {onClose && (
                <Button
                  type="text"
                  className="btn-close"
                  size="large"
                  onClick={closeHandler}
                >
                  <CloseOutlined />
                </Button>
              )}
            </div>
          </div>
        </Flex>
      </Affix>
      <p style={{ width: "500px" }}>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam, enim
        officia aspernatur
      </p>
    </div>
  );
};

export default PageHeader;
