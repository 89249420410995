import React, { useEffect, useState } from 'react'
import { Form, Input, Typography, Row, Col, Select, Button, Radio, Space, InputNumber } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import classDetailService from 'src/services/master/classDetailService';
import { ClassDetail } from 'src/domain/master/classDetailType';
import { Application } from 'src/domain/application/applicationType';
import { IncomeSource } from 'src/domain/application/incomeSourceType';

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IProps {
    application : any;
    trigger: any;
    setTrigger: any;
    updateIncomeData : any;
    next: () => void;
}

const options_income = ['เงินเดือน', 'อื่นๆ']

const IncomeApplication : React.FC<IProps> = (props) => {
    const {application, trigger, setTrigger, updateIncomeData, next} = props;
    const [form] = Form.useForm();

    const [optionsIncome, setOptionsIncome] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [optionsIncomeOther, setOptionsIncomeOther] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [optionsInvoiceLocation, setOptionsInvoiceLocation] = useState<ClassDetail[]>([] as ClassDetail[]);

    const [selectIncome, setSelectIncome] = useState<ClassDetail>();
    const [selectIncomeOther, setSelectIncomeOther] = useState<ClassDetail>();
    const [selectInvoiceLocation, setSelectInvoiceLocation] = useState<number>(34);
    
    useEffect(() => {
        (async () => {
            const res = await classDetailService.getByClassNo("05");
            setOptionsIncome(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await classDetailService.getByClassNo("07");
            setOptionsInvoiceLocation(res.data);
        })();
        if (application.invoiceLocationId) {
            setSelectInvoiceLocation(application.invoiceLocationId);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const res = await classDetailService.getByClassNo("05");
            setOptionsIncomeOther(res.data);
        })();
    }, []);

    useEffect(() => {
        if (trigger) {
          form.submit();
        }
    }, [trigger]);

    useEffect(() => {
        let _incomeNormal;
        let _incomeOther;
        if(application.incomeSource) {
            for(let i=0; i<application.incomeSource.length; i++) {
                const _inc = application.incomeSource[i];
                console.log("_inc", _inc)
                if(_inc.incomeType === "NORMAL") {
                    _incomeNormal = _inc.incomeSourceId;
                    const x : any  = {id :_inc.incomeSourceId, name: _inc.incomeSourceName}
                    setSelectIncome(x);
                } else {
                    _incomeOther = _inc.incomeSourceId;
                    const x : any  = {id :_inc.incomeSourceId, name: _inc.incomeSourceName}
                    setSelectIncomeOther(x);
                } 
            }
        }
        form.setFieldsValue({
            income_amt: application.incomeAmt,
            other_income_amt: application.otherIncomeAmt,
            income_source: _incomeNormal,
            other_income_source: _incomeOther,
            invoice_location: application.invoiceLocationId,
        });

        if(application.invoiceLocationId) 
            setSelectInvoiceLocation(application.invoiceLocationId);

    }, [application]);

    const onSelectIncome = (value : any, index : any) => {
        console.log("select income ", index);
        const x : any  = {id :value, name: index.children};
        setSelectIncome(x);
    }

    const onSelectIncomeOther = (value : any, index : any) => {
        const x : any  = {id :value, name: index.children};
        setSelectIncomeOther(x);
    }

    const onChangeInvoiceLocation = (values : any) => {
        // console.log("invoice loc ", values.target.value);
        setSelectInvoiceLocation(values.target.value);
    }

    const onFinish = (values: any) => {
        console.log("income values:", values);
        const _app = {
            incomeAmt: values.income_amt,
            otherIncomeAmt : values.other_income_amt,
            selectIncome : selectIncome,
            selectIncomeOther : selectIncomeOther,
            incomeOtherInput : values.income_other_input,
            invoiceLocationId : selectInvoiceLocation,
        };

        updateIncomeData(_app);
        next();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        setTrigger(false);
    };

    return (
        <>
            <Form
                // {...layout}
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Typography>
                    <Title level={2}>ข้อมูล รายได้</Title>
                    <Paragraph>ระบุ ข้อมูลรายได้ และแหล่งที่มาของรายได้</Paragraph>
                </Typography>
                <Row style={{ marginBottom: "3em" }}>
                    <Col span={24}>
                        <Space direction="vertical" />

                        <Form.Item
                            label="รายได้/เดือน"
                            name="income_amt"
                            rules={[
                                {
                                  type: 'number',
                                  required: true,
                                  message: 'กรุณาระบุรายได้/เดือน!',
                                  whitespace: true,
                                },
                            ]}
                        >
                            {/* <Input type="number"/> */}
                            <InputNumber
                                // onChange={onChangeLoanAmt}
                                precision={2}
                                style={{ width: 250, textAlign: "right", alignItems:"end"}} 
                            />
                        </Form.Item>

                        <Form.Item
                            label="รายได้อื่น(ถ้ามี)"
                            name="other_income_amt"
                        >
                            <InputNumber
                                // onChange={onChangeLoanAmt}
                                precision={2}
                                style={{ width: 250, textAlign: "right", alignItems:"end"}} 
                            />
                        </Form.Item>

                        <Form.Item
                            label="แหล่งที่มารายได้"
                            name="income_source"
                            // rules={ [
                            //     {
                            //       type: 'object',
                            //       required: true,
                            //       message: 'กรุณาเลือกแหล่งที่มารายได้!',
                            //       whitespace: true,
                            //     },
                            // ]}
                        >
                            <Select 
                                placeholder="เลือกรายการ" 
                                style={{ width: 200 }}
                                onSelect={onSelectIncome}
                                value={selectIncome?.id}
                            >
                                {optionsIncome.map((opt_value) => (
                                    <Option key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="แหล่งที่มารายได้อื่นๆ"
                            name="other_income_source"
                        >
                            <Select 
                                placeholder="เลือกรายการ" 
                                style={{ width: 200 }}
                                onSelect={onSelectIncomeOther}
                                value={selectIncomeOther?.id}
                            >
                                {optionsIncomeOther.map((opt_value) => (
                                    <Option key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                ))}
                            </Select>
                            <Input name="income_other_input" style={{ width: 200, marginLeft: "10px"  }} />
                        </Form.Item>

                        <label style={{ marginTop: "40px" }}> สถานที่จัดส่งใบแจ้งยอด/ใบเสร็จการชำระเงินกู้ และเอกสารอื่นๆ</label>
                        <Form.Item
                            name="invoice_location"
                        >
                            <Radio.Group 
                                onChange={onChangeInvoiceLocation}
                                value={selectInvoiceLocation}
                                defaultValue={selectInvoiceLocation}
                            >
                                {optionsInvoiceLocation.map((opt_value) => (
                                    <Radio key={opt_value.id} value={opt_value.id} >{opt_value.name}</Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{ offset: 22}}
                        >
                            <Button
                                size="small"
                                type="primary"
                                icon={<RightCircleOutlined />}
                                onClick={() => {form.submit()}}
                            >
                                Next
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            
        </>
    )
}

export default IncomeApplication
