import { Form, Button, Tabs, DatePicker, Select, Row, Col, Space, Input, Table, InputNumber, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import {SaveOutlined, PlusOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import classes from "src/views/application/ListApplication.module.css";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import TextArea from 'antd/lib/input/TextArea';
import { Company } from 'src/domain/master/companyType';
import companyService from 'src/services/master/companyService';
import bookTypeService from 'src/services/account/bookTypeService';
import { BookType } from 'src/domain/account/booktypeType';
import { Book } from 'src/domain/account/book';
import { BookItem } from 'src/domain/account/bookItemType';
import { Account } from 'src/domain/account/accountType';
import accountService from 'src/services/account/accountService';
import { BookCode } from 'src/domain/account/bookCodeType';
import bookCodeService from 'src/services/account/bookCodeService';
import { ClassDetail } from 'src/domain/master/classDetailType';
import classDetailService from 'src/services/master/classDetailService';
import bookService from 'src/services/account/bookService';
import { Payee } from 'src/domain/account/payeeType';
import payeeService from 'src/services/account/payeeService';
import NewPayeePopup from './NewPayeePopup';

const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';

interface IProps {
    id: number;
    mode: string;
}

const AccBookForm: React.FC<IProps> = (props) => {
    const id = props.id;
    const mode = props.mode;
    const history = useHistory();
    const [form] = Form.useForm();

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [book, setBook] = useState<Book>({} as Book);
    const [bookItems, setBookItems] = useState<BookItem[]>([] as BookItem[]);

    const [optionsBookType, setOptionsBookType] = useState<BookType[]>([] as BookType[]);
    // const [selectBookType, setSelectBookType] = useState<number>(0);
    const [selectBookType, setSelectBookType] = useState<BookType>();

    const [optionsBookCode, setOptionsBookCode] = useState<BookCode[]>([] as BookCode[]);
    // const [selectBookCode, setSelectBookCode] = useState<number>(0);
    const [selectBookCode, setSelectBookCode] = useState<BookCode>();

    const [optionsCompany, setOptionsCompany] = useState<Company[]>([] as Company[]);
    const [selectCompany, setSelectCompany] = useState<number>(0);

    const [optionsPayee, setOptionsPayee] = useState<Payee[]>([] as Payee[]);
    const [selectPayee, setSelectPayee] = useState<Payee>();
    const [selectPayeeId, setSelectPayeeId] = useState<number>(0);

    const [optionsPayType, setOptionsPayType] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [selectPayType, setSelectPayType] = useState<ClassDetail>();

    const [accounts, setAccounts] = useState<Account[]>([] as Account[]);
    const [totalCredit, setTotalCredit] = useState<number>(0);



    const columns = [
        {
            render: (value: any, item: BookItem, index: number) => (
                <Space>
                    <Tooltip placement="topLeft" title="Delete">
                        <Button
                            size="small"
                            shape="circle"
                            danger
                            icon={<DeleteOutlined  />}
                            onClick={() => {
                                console.log("index", index)
                                const newData = [...bookItems];
                                newData.splice(index, 1);
                                setBookItems(newData);
                            }}
                        />
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Copy">
                        <Button
                            size="small"
                            shape="circle"
                            icon={<CopyOutlined />}
                            onClick={() => {
                                const newData = [...bookItems];
                                const item = newData[index];
                                newData.push(item);
                                setBookItems(newData);
                            }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
          render: (value: string, item: BookItem, index: number) => (
            index+1
          ),
        },
        {
            title: "รหัสบัญชี/ชื่อบัญชี",
            dataIndex: "accountId",
            // key: "accountId",
            render : (value: number, item: BookItem, index: number) => (
                <Form.Item
                    style={{
                    margin: 0,
                    }}
                    name={index}
                    rules={[
                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                    ]}
                >
                <Select 
                    placeholder="เลือกรหัสบัญชี/ชื่อบัญชี" 
                    style={{ width: 200 }}
                    onSelect={(_value: any, event: any) => {
                        const newData = [...bookItems];
                        const item = newData[index];
                        newData.splice(index, 1, 
                            { ...item, 
                                accountId : _value, 
                                accountCode: event.props.code,  
                            }
                        );
                        setBookItems(newData);
                    }}
                    value={value}
                    defaultValue={value}
                    filterOption={(input, option) => {
                        return (
                            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                        );
                    }}
                    showSearch
                    allowClear
                >
                    {accounts.map((opt_value) => (
                        <Option 
                            key={opt_value.code} 
                            value={opt_value.id} 
                            title={opt_value.code + ''+ opt_value.name}
                            props={opt_value}
                        >
                            {opt_value.code} {opt_value.name}
                        </Option>
                    ))}
                </Select>
                </Form.Item>
            ),
        },
        {
            title: "เดบิต",
            dataIndex: "debit",
            // key: "debit",
            align: "right" as "right",
            render: (value: number, item: BookItem, index: number) => (
                <Form.Item
                    style={{
                    margin: 0,
                    }}
                    // name="_debit"
                >
                    <InputNumber 
                        // formatter={_value => `${_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                        value={value} 
                        onKeyUp={(event: any) => {
                            const newData = [...bookItems];
                            const _item = newData[index];
                            newData.splice(index, 1, { ..._item, debit : event.target.value,  credit : event.target.value !==0 ? 0 : _item.credit});
                            setBookItems(newData);
                        }}  
                    />
                </Form.Item>
            )
        },
        {
            title: "เครดิต",
            dataIndex: "credit",
            // key: "credit",
            align: "right" as "right",
            render: (value: number, item: BookItem, index: number) => (
                <Form.Item
                    style={{
                    margin: 0,
                    }}
                    // name="cr"
                >
                    {/* <Tooltip placement="topLeft" title={value}> */}
                    <InputNumber
                        defaultValue={0}
                        value={value}
                        onBlur={(event: any) => {
                            const newData = [...bookItems];
                            const _item = newData[index];
                            newData.splice(index, 1, { ..._item, credit : event.target.value,  debit : event.target.value !==0 ? 0 : _item.debit});
                            setBookItems(newData);
                        }}  
                    />
                    {/* </Tooltip> */}
                </Form.Item>
            )
        },
        {
            title: "หมายเหตุ",
            dataIndex: "description",
            key: "description",
            render: (value: string, item: BookItem, index: number) => (
                <Form.Item
                    style={{
                    margin: 0,
                    }}
                    name="desc"
                >
                    <Tooltip placement="topLeft" title={value}>
                    <TextArea rows={1} cols={50} showCount maxLength={1000} 
                        value={value}
                        onBlur={(event: any) => {
                            const newData = [...bookItems];
                            const item = newData[index];
                            newData.splice(index, 1, { ...item, description : event.target.value });
                            setBookItems(newData);
                        }} 
                    />
                    </Tooltip>
                </Form.Item>
            )
        },
        {
            title: "สาขา",
            dataIndex: "",
            key: "",
          },
        // {
        //     title: "วันที่ยื่นภาษี",
        //     dataIndex: "makeDate",
        //     key: "makeDate",
        // //   render: (value: string) => moment(value).format(dateFormat),
        //     render: (value: string) => (
        //         <DatePicker 
        //             locale={th_loc}
        //             // onChange={onChangeStartDate}
        //             // defaultValue={moment(moment().format('L'), dateFormat)}
        //             format={dateFormat}
        //         />
        //     )
        // },
        {
            title: "เลขที่เอกสารอ้างอิง",
            dataIndex: "",
            key: "",
            render: (value: string) => (
                <Input  />
            )
        },
        {
            title: "วันที่เอกสารอ้างอิง",
            dataIndex: "",
            key: "",
          //   render: (value: string) => moment(value).format(dateFormat),
            render: (value: string) => (
                <DatePicker 
                    locale={th_loc}
                    // onChange={onChangeStartDate}
                    // defaultValue={moment(moment().format('L'), dateFormat)}
                    format={dateFormat}
                />
            )
        },
    //     {
    //       title: "สาขาของผู้ขาย/ลูกค้า",
    //       dataIndex: "",
    //       key: "",
    //     },
    //     {
    //       title: "เลขที่ผู้เสียภาษีผู้ขาย/ลูกค้า",
    //       dataIndex: "",
    //       key: "",
    //     },
    ];

    useEffect(() => {
        if(mode === 'NEW') {
            const _book: Book = {
                ...book,
                bookItems: [],
            };
            _book.bookItems.push({} as BookItem);
            _book.bookItems.push({} as BookItem);
            setBookItems(_book.bookItems);
            setBook(_book);
        }
    }, []);

    useEffect(() => { 
        console.log("item size =", bookItems.length)
        console.log("item=", bookItems)
        bookItems.forEach(({ debit, credit }) => {
            console.log("credit=", credit)
            // totalDebit = totalDebit + (debit ? debit : 0);
            // totalCredit = totalCredit + (credit ? credit : 0);
            if(credit !== undefined) {
                setTotalCredit((x) => x + (credit && credit !== undefined ? credit : 0));
                console.log("credit != undefined")
            }
        });

    },[bookItems, book]);

    useEffect(() => {
        (async () => {
            const res = await companyService.getCompanyAll();
            setOptionsCompany(res.data);
            // console.log("company");
            if(mode === 'NEW') {
                
                setSelectCompany(1);
                // console.log("company id=",res.data[0].id)
                form.setFieldsValue({company_id: 1});
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await bookTypeService.getBookTypeAll();
            setOptionsBookType(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await accountService.getAccountsUseOnly();
          setAccounts(res.data);
        })();
    },[]);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("17");
          setOptionsPayType(res.data);
        })();
    }, []);

    useEffect(() => {
        if(selectBookType) {
            (async () => {
                const res = await bookCodeService.getBookCodeByBookTypeId(selectBookType.id);
                setOptionsBookCode(res.data);
            })();
        }
    }, [selectBookType]);

    useEffect(() => {
        (async () => {
          const res = await payeeService.getPayeesUseOnly();
          setOptionsPayee(res.data);
        })();
    }, []);

    useEffect(() => {
        if(selectPayee) {
            form.setFieldsValue({payee : selectPayee.id,})
        }
    }, [optionsPayee])

    const onChangeDocDate  = (value : any) => {
        console.log("doc date", value ? value.format('YYYYMMDD') : "")
        if(value) {
            form.setFieldsValue({accSaveDate : value ? moment(value, 'YYYY-MM-DD') : "",})
        }
    }

    const onSelectCompany = (value: any) => {
        setSelectCompany(value);
    }

    const onSelectBookType = (value: any, event: any) => {
        setSelectBookType(event.props);
        setSelectBookCode({} as BookCode);
        form.setFieldsValue({bookCode : selectBookCode?.id});
    }

    const onSelectBookCode = (value: any, event: any) => {
        // setSelectBookCode(value);
        setSelectBookCode(event.props);
    }

    const onSelectPayee = (value: any, event: any) => {
        setSelectPayee(event.props);
    }

    const onSelectPayType = (value: any, event: any) => {
        setSelectPayType(event.props);
    }

    const addNewItem = () => {
        const newData = [...bookItems];
        newData.push({} as BookItem);
        setBookItems(newData);
    }

    const onFinish = async (values: any) => {
        const _book : Book = {
            ...book,
            companyId : selectCompany,
            docDate : values.docDate.format('YYYYMMDD'),
            accSaveDate : values.accSaveDate.format('YYYYMMDD'),
            remark : values.remark,
            bookTypeId : selectBookType?.id!,
            bookTypeCode : selectBookType?.code!,
            bookTypeName : selectBookType?.name!,
            bookCodeId : selectBookCode?.id!,
            bookCodeCode : selectBookCode?.code!,
            bookCodeName : selectBookCode?.name!,
            // refDocDate : values.
            refDocNo : values.refDocNo,

            payTypeId : selectPayType?.id ?? 0,
            payType : selectPayType?.name ?? '',
            payeeId : selectPayee?.id ?? 0,
            payeeName : selectPayee?.name ?? '',
            chequeNo : values.chequeNo,
            chequeDate : values.chequeDate ? values.chequeDate.format('YYYYMMDD') : "",
            chequeInName : values.chequeInName,
            accountNo : values.accountNo,
            bankName : values.bankName,
            branchName : values.branchName,
            bookItems: [],
        };

        _book.bookItems.push(...bookItems);

        console.log("save=", _book);

        if(mode === 'NEW') {
            const res = bookService.addBook(_book).then((res) => {
                if (res.status === 200) history.goBack();
            }).catch((err) => {
                console.log(err);
            });
        }else if(mode === 'EDIT') {
            await bookService.editBook(_book).then(res => {
                if(res.status === 200)
                    history.replace("/app/accbook/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> NewBook:", errorInfo);
    };

    const showModal = () => {
        setModalVisible(true);
    }

    const onOkNewPayee = (value: Payee) => {
        setModalVisible(false);

        console.log("payee =",value)

        const _payees = [...optionsPayee]
        _payees.push(value);
        console.log("payee size=", _payees.length)
        setOptionsPayee(_payees);
        form.setFieldsValue({payee : value.id,})
        setSelectPayee(value);


        // await payeeService.addPayee(value).then((res) => {
        //     console.log("save=", res.data)
        //     if (res.status === 200) {
                // const _payees = {...optionsPayee}
                // _payees.push(value);
                // console.log("payee size=", _payees.length)
                // setOptionsPayee(_payees);

                // setSelectPayee(value);
        //     }
        // }).catch((err) => {
        //     console.log(err);
        // });

        
        
        // setSelectPayeeId(value);
    }

    const onRowSummary = () => {
        let totalDebit = 0;
        // let totalCredit = 0;
        

        bookItems.forEach(({ debit, credit }) => {
            totalDebit = totalDebit + (debit ? debit : 0);
            // totalCredit = totalCredit + (credit ? credit : 0);
            setTotalCredit((x) => x + (credit ?? 0));
        });

        return (
            <>
              <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                      Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                      {totalDebit}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                      {totalCredit}
                  </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
    }

    return (
        <div>
            <PageHeader title="บันทึกสมุดบันทึกบัญชี" onClose={() => history.goBack()}>
                <Button
                    size="large"
                    type="primary"
                    danger
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                    Save
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    {/* <div className={classes.dataPanel}> */}
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="ข้อมูลเอกสาร" key="1">
                            <div className={classes.dataPanel}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{marginLeft: "20px",width: "50%"}}>
                                    <Form.Item
                                        label="วันที่สร้าง"
                                        name="docDate"
                                        rules={ [
                                            {
                                              type: 'object',
                                              required: true,
                                              message: 'กรุณาระบุวันที่สร้าง!',
                                              whitespace: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker 
                                            locale={th_loc}
                                            onChange={onChangeDocDate}
                                            // defaultValue={moment(moment().format('L'), dateFormat)}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="วันที่บันทึกบัญชี"
                                        name="accSaveDate"
                                        rules={ [
                                            {
                                              type: 'object',
                                              required: true,
                                              message: 'กรุณาระบุวันที่บันทึกบัญชี!',
                                              whitespace: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker 
                                            locale={th_loc}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="เลขที่อ้างอิง"
                                        name="refDocNo"
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div style={{ marginLeft: "20px", width: "50%"}}>
                                    <Form.Item
                                        label="บริษัท"
                                        name="company_id"
                                        rules={[
                                            { required: true, message: "กรุณาเลือกข้อมูล!" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="เลือกบริษัท"
                                            style={{ width: "80%" }}
                                            onSelect={onSelectCompany}
                                            value={selectCompany}
                                            // defaultValue={selectCompany}
                                            filterOption={(input, option) => {
                                                return (
                                                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                            showSearch
                                        >
                                            {optionsCompany.map((opt_value) => (
                                                <Option title={opt_value.name} key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="ประเภทสมุด"
                                        name="bookType"
                                        rules={[
                                            { required: true, message: "กรุณาเลือกข้อมูล!" },
                                        ]}
                                    >
                                        <Select 
                                            placeholder="เลือกประเภทสมุด" 
                                            style={{ width: "80%" }}
                                            onSelect={onSelectBookType}
                                            value={selectBookType?.id}
                                        >
                                        {optionsBookType.map((values) => (
                                            <Option key={values.id} value={values.id} props={values}>
                                                {values.name}
                                            </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="ชื่อสมุด"
                                        name="bookCode"
                                        rules={[
                                            { required: true, message: "กรุณาเลือกข้อมูล!" },
                                        ]}
                                    >
                                        <Select 
                                            placeholder="เลือกชื่อสมุด" 
                                            style={{ width: "80%" }}
                                            onSelect={onSelectBookCode}
                                            value={selectBookCode?.id}
                                        >
                                            {optionsBookCode.map((values) => (
                                                <Option key={values.id} value={values.id} props={values}>
                                                    {values.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                </div>
                                <Form.Item
                                    label="หมายเหตุ"
                                    name="remark"
                                    style={{marginLeft: "20px",}}
                                >
                                    <TextArea rows={2} cols={40} style={{width:"90%"}} showCount maxLength={1000} />
                                </Form.Item>
                            </div>
                        </TabPane>

                        <TabPane tab="ข้อมูลผู้รับเงิน" key="2">
                            <div className={classes.dataPanel}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginLeft: "20px", width: "50%"}}>
                                        <Form.Item
                                            label=""
                                            name=""
                                        >
                                            <Row>
                                                <Col style={{width:"80%"}}>
                                                    <Form.Item
                                                        label="ผู้รับเงิน/ลูกค้า"
                                                        name="payee"
                                                    >
                                                        <Select 
                                                            placeholder="เลือกผู้รับเงิน/ลูกค้า" 
                                                            style={{ width: "100%" }}
                                                            onSelect={onSelectPayee}
                                                            value={selectPayee?.id}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );
                                                            }}
                                                            showSearch
                                                        >
                                                            {optionsPayee.map((opt_value) => (
                                                                <Option 
                                                                    title={opt_value.name +''+ opt_value.taxNo + '' + opt_value.tel} 
                                                                    key={opt_value.id} 
                                                                    value={opt_value.id}
                                                                    props={opt_value}
                                                                >
                                                                    {opt_value.name}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{width:"20%"}}>
                                                    <Form.Item
                                                        label=" "
                                                        name="new_payee"
                                                    >
                                                        <Button
                                                            size="small"
                                                            shape="circle"
                                                            icon={<PlusOutlined />}
                                                            style={{marginTop:1, marginLeft:2}}
                                                            onClick={() => showModal()}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            
                                        </Form.Item>
                                        <Form.Item
                                            label="เลขที่บัญชี"
                                            name="accountNo"
                                        >
                                            {/* <Select 
                                                placeholder="เลือกเลขที่บัญชี" 
                                                style={{ width: "80%" }}
                                                
                                                // onSelect={onSelectOccupation}
                                                // value={selectOccupation?.id}
                                            ></Select> */}
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="ธนาคาร"
                                            name="bankName"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="เลขที่เช็ค"
                                            name="chequeNo"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="วันที่จ่ายเช็ค"
                                            name="chequeDate"
                                        >
                                            <DatePicker 
                                                locale={th_loc}
                                                // onChange={onChangeStartDate}
                                                // defaultValue={moment(moment().format('L'), dateFormat)}
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{ marginLeft: "20px", width: "50%"}}>
                                        <Form.Item
                                            label=""
                                            name=""
                                        >
                                            <Row>
                                                <Col style={{width:"99%"}}>
                                                    <Form.Item
                                                        label="ประเภทการจ่าย"
                                                        name="payType"
                                                    >
                                                        <Select 
                                                            placeholder="เลือกประเภทการจ่าย" 
                                                            style={{ width: "80%" }}
                                                            
                                                            onSelect={onSelectPayType}
                                                            value={selectPayType?.id}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );
                                                            }}
                                                            showSearch
                                                        >
                                                            {optionsPayType.map((opt_value) => (
                                                                <Option 
                                                                    key={opt_value.id} 
                                                                    value={opt_value.id} 
                                                                    title={opt_value.name}
                                                                    props={opt_value}
                                                                >
                                                                    {opt_value.name}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{width:"1%"}}>
                                                    <Form.Item
                                                        label=" "
                                                        name=""
                                                    >
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            
                                        </Form.Item>
                                        <Form.Item
                                            label=" "
                                        >
                                            {/* <Input  /> */}
                                        </Form.Item>
                                        <Form.Item
                                            label="สาขา"
                                            name="branchName"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="สั่งจ่ายเช็คในนาม"
                                            name="chequeInName"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                    {/* </div> */}
                    <div className={classes.dataPanel} style={{marginTop:"10px"}}>
                        <Table
                            columns={columns}
                            // dataSource={[...bookItems]}
                            dataSource={bookItems}
                            size="small"
                            rowKey="id"
                            pagination={false}
                            scroll={{ x: 1000}}
                            // summary={onRowSummary}
                            summary={() => (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={2} ></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>รวม</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>600</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{totalCredit}</Table.Summary.Cell>
                                </Table.Summary.Row>
                                )}
                        />
                        <Button
                            size="small"
                            shape="circle"
                            icon={<PlusOutlined />}
                            style={{marginTop:5}}
                            onClick={() => (addNewItem())}
                        />
                        
                    </div>
                </Form>
            </Content>

            <NewPayeePopup
                modalVisible={modalVisible}
                onOkNewPayee={onOkNewPayee}
                onCancel={() => setModalVisible(false)}
            />
        </div>
    )
}

export default AccBookForm
