import { Button, Descriptions, Upload, Image } from 'antd';
import {UploadOutlined} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';
import FileUpload from 'src/components/input/FileUpload';
import ResourceFileResponse from 'src/models/ResourceFileResponse';
import { UploadFile } from 'antd/lib/upload/interface';
import applicationService from 'src/services/application/applicationService';
import ResourceFile from 'src/models/ResouceFile';
import ApplicationResource from 'src/models/ApplicationResource';
import Configs from 'src/constants/config';

interface IProps {
    id : number;
}

const ViewApplicationResouce : React.FC<IProps> = (props) => {
    const _id: number = props.id;
    const [idcardResource, setIdcardResource] = useState<ApplicationResource | null>(null);
    const [bookBankResource, setBookBankResource] = useState<ApplicationResource | null>(null);

    useEffect(() => {
        console.log("ApplicationResource Load.");
        loadApplicationResource(props.id);
    }, [props.id]);

    const loadApplicationResource = async(applicationId: number) => {
        await applicationService.getApplicstionResourceByApplicationId(applicationId).then((res) => {
            console.log("res.data: ", res.data);
            let resData: Array<ApplicationResource> = res.data;
            if(resData != null && resData.length > 0){
                resData.forEach((data, index) => {
                    if(data.attachFileId == 38){
                        setIdcardResource(data);
                    }else if(data.attachFileId == 39){
                        setBookBankResource(data);
                    }
                })
            }
        })
    }

    const onIdcardUploaded = (uploadStatus: string, resourceFile: ResourceFileResponse, file: UploadFile<any>) => {
        if(uploadStatus == 'success'){
            let resourceData: ResourceFile = resourceFile.resourceData != null ? resourceFile.resourceData : new ResourceFile(-1, "", "", "", "");
            applicationService.updateAttatchFile(_id, resourceData.id, null).then((res) => {
                setIdcardResource(res.data);
            });
        }
    }

    const onBookBankUploaded = (uploadStatus: string, resourceFile: ResourceFileResponse, file: UploadFile<any>) => {
        if(uploadStatus == 'success'){
            let resourceData: ResourceFile = resourceFile.resourceData != null ? resourceFile.resourceData : new ResourceFile(-1, "", "", "", "");
            applicationService.updateAttatchFile(_id, null, resourceData.id).then((res) => {
                setBookBankResource(res.data);
            });
        }
    }

    return (
        <div>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            span={2}
                            label="สำเนาบัตรประชาชน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            <FileUpload onUploaded={onIdcardUploaded} resourceId={idcardResource != null ? idcardResource.resourceId : undefined} filename={"สำเนาบัตรประชาชน"} fileType={"DOC"}/>
                        </Descriptions.Item>

                        <Descriptions.Item
                            span={2}
                            label="สำเนาบัญชีเงินฝาก :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            <FileUpload onUploaded={onBookBankUploaded} resourceId={bookBankResource != null ? bookBankResource.resourceId : undefined} filename={"สำเนาบัญชีเงินฝาก"} fileType={'PIC'}/>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
        </div>
    )
}

export default ViewApplicationResouce
