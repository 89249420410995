import http from "../../lib/http";

const url = "http://localhost:8080/account";

const getAccounts = () => {
    return http.get(url);
};

const getAccountsUseOnly = () => {
    return http.get(url+"/allUseOnly");
};

const getAccountById = (id: number) => {
    return http.get(url+"/"+id);
}

const getAccountGroup = (type: string, side: string, level: string) => {
    return http.get(url+"/accountgroup?type="+type+ "&side="+side+ "&level="+level);
}

const searchByCodeOrName = (text: string) => {
    return http.get(url+"/codeorname?text="+text);
}

const codeExist = (code: string, id: number) => {
    return http.get(url+"/codeExist?code="+code + "&id="+id);
}

const addAccount = (acc : any) => {
    return http.post(url, acc);
} 

const editAccount = (account : any) => {
    return http.put(url+"/"+account.id, account);
} 

const editUseFlg = (id : number, useFlg: number) => {
    return http.put(url+"/editUseFlg/"+id+"/"+useFlg);
}

export default {
    getAccounts,
    getAccountsUseOnly,
    getAccountById,
    getAccountGroup,
    searchByCodeOrName,
    codeExist,
    addAccount,
    editAccount,
    editUseFlg,
}