import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {PlusOutlined} from "@ant-design/icons"
import { Content } from 'antd/lib/layout/layout';

import moment from 'moment';
import 'moment/locale/th';
import paymentService from 'src/services/payment/paymentService';
import { Payment } from 'src/domain/payment/paymentType';
import { Link } from 'react-router-dom';

const dateFormat = "DD/MM/YYYY";

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: Payment, index: number) => (
          index+1
        ),
    },
    {
        title: "วันที่รับชำระ",
        dataIndex: "paymentDate",
        key: "paymentDate",
        render: (value: string) => moment(value).format(dateFormat),
    },
    {
        title: "เลขที่รับชำระ",
        dataIndex: "paymentNo",
        key: "paymentNo",
        render: (value: string, item: Payment, index: number) => (
            <Link to={`/app/payment/view/${item.id}`}>{value}</Link>
          ),
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "contractNo",
        key: "contractNo",
    },
    {
        title: "ยอดเงิน",
        dataIndex: "totalAmt",
        key: "totalAmt",
        align: "right" as "right",
        render: (value: number) =>
            Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
    },
    {
        title: "สาขา",
        dataIndex: "paymentStation",
        key: "paymentStation",
    },
    {
        title: "ผู้รับเงิน",
        dataIndex: "createUserName",
        key: "createUserName",
    },
];
const ListPayment = () => {
    const history = useHistory();
    const [payment, setPayment] = useState<Payment[]>([]);

    useEffect(() => {
        (async () => {

          const res = await paymentService.getByStation("11");
          setPayment(res.data);

        })();
    },[])
    return (
        <div>
            <PageHeader title="Payment List">
                <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/payment/new")}
                >
                New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div>
                    <Table
                    columns={columns}
                    dataSource={payment}
                    size="small"
                    rowKey={(item: Payment) => item.paymentNo}
                    />
                </div>
            </Content>
        </div>
    )
}

export default ListPayment
