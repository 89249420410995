import { Descriptions } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { IncomeSource } from 'src/domain/application/incomeSourceType';

interface IProps {
    application : any;
}

const ViewIncomeApplication : React.FC<IProps> = (props) => {
    const {application} = props;
    const history = useHistory();
    const [incomeNormal, setIncomeNormal] = useState<IncomeSource>({} as IncomeSource);
    const [incomeOther, setIncomeOther] = useState<IncomeSource>({} as IncomeSource);

    useEffect(() => {
        const _incomes  = application.incomeSource.length;
        console.log("income size", _incomes.length)
        for(let x=0; x<application.incomeSource.length; x++) {
            const _inc = application.incomeSource[x];
            console.log("_inc", _inc)
            if(_inc.incomeType === "NORMAL") setIncomeNormal(_inc);
            else setIncomeOther(_inc);
        }
    }, [application])

    return (
        <div>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        // title="Personal Information"
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="รายได้/เดือน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {Number(Number(application.incomeAmt).toFixed(2)).toLocaleString('th-TH')}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="รายได้อื่น(ถ้ามี) :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {Number(Number(application.otherIncomeAmt).toFixed(2)).toLocaleString('th-TH')}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="แหล่งที่มารายได้ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {incomeNormal.incomeSourceName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="แหล่งที่มารายได้อื่นๆ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {incomeOther ? incomeOther.incomeSourceName : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="สถานที่จัดส่งใบแจ้งยอด/ใบเสร็จการชำระเงินกู้ และเอกสารอื่นๆ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {application.decitionDocChannalName}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
        </div>
    )
}

export default ViewIncomeApplication
