import { Button, Col, DatePicker, Descriptions, Form, Input, Row, Space, Table} from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router';
import { Contract } from 'src/domain/contract/contractType';
import {SaveOutlined} from '@ant-design/icons';
import PageHeader from 'src/layouts/PageHeader';
import contractService from 'src/services/contract/contractService';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { ContractDebit } from 'src/domain/contract/contractDebitType';

const dateFormat = 'DD/MM/YYYY';

interface IParams {
    id: string;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

const columns = [
    {
        title: "Mth",
        dataIndex: "month",
        key: "month",
    },
    {
        title: "วันที่กำหนดชำระ",
        dataIndex: "duedate",
        key: "duedate",
        render: (value: string) => value ? moment(value).format(dateFormat) : "",
    },
    {
        title: "Installment",
        dataIndex: "installment",
        key: "installment",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Interest Revenues",
        dataIndex: "interestRevenues",
        key: "interestRevenues",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Princ Repaid",
        dataIndex: "princPepaid",
        key: "princPepaid",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Ending Bal.",
        dataIndex: "endingBal",
        key: "endingBal",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "CF",
        dataIndex: "cf",
        key: "cf",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "% ตัดต้น",
        dataIndex: "principlePer",
        key: "principlePer",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
]

const ContractForm : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);
    const history = useHistory();
    const [form] = Form.useForm();

    const [contract, setContract] = useState<Contract>({} as Contract);
    const [contractDebitLists, setContractDebitLists] = useState<ContractDebit[]>([] as ContractDebit[]);

    useEffect(() => {
        // (async () => {
            contractService.getContractById(_id).then(res => {
                setContract(res.data);
            });
        // })
    }, []);

    useEffect(() => {
        // (async () => {
            contractService.getDebitbyContractId(_id).then(res => {
                setContractDebitLists(res.data);
            });
        // })
    }, []);

    useEffect(() => {}, [contractDebitLists]);

    useEffect(() => {
        form.setFieldsValue({
            contract_date : contract.contractDate ? moment(contract.contractDate, 'YYYY-MM-DD') : "",
            pay_date : contract.payDate ? moment(contract.payDate, 'YYYY-MM-DD') : "",
        });
    }, [contract]);

    const onChangeContractDate = (value: any) => {
        console.log("contract date", value ? value.format('YYYYMMDD') : "")
        const _contractDate = value ? value.format('YYYYMMDD') : ""
        if(_contractDate) {
            // (async () => {
                contractService.calculateDueDate(_id, _contractDate).then(res => {
                    setContractDebitLists(res.data);
                });
            // });
        }
    }

    const onFinish = async (values: any)  => {
        const _contract: Contract = {
            ...contract,
            contractDate: values.contract_date.format('YYYYMMDD'),
            payDate: values.pay_date.format('YYYYMMDD'),
            debits: contractDebitLists
        }

        await contractService.updateContractDate(_contract).then(res => {
            if(res.status === 200)
                history.replace("/app/contract/view/"+res.data.id);
        }).catch(err => {
            console.log(err)
        });
    }

    const onFinishFailed = () => {

    }

    return (
        <div>
            <PageHeader
                title={"บันทึกวันที่สัญญา"}
                onClose={() => history.goBack()}
            >
                <Button
                        size="large"
                        type="primary"
                        danger
                        icon={<SaveOutlined />}
                        onClick={() => form.submit()}
                    >
                        Save
                    </Button>
            </PageHeader>
            <Form
                {...layout}
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row style={{ marginBottom: "3em" }}>
                    <Col span={24} style={{ marginTop: "2em", border: "1px solid white", padding: "10px"}} >
                        <Descriptions
                            style={{ marginTop: "20px" }}
                            column={2}
                            size="middle"
                            labelStyle={{ width: 200, fontWeight: "bold" }}
                            bordered
                        >
                            <Descriptions.Item
                                label="เลขที่สัญญา :"
                                span={2}
                                labelStyle={{ textAlign: "right" }}
                            >
                                {contract.contractNo}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label="เลขที่ใบคำขอสินเชื่อ :"
                                labelStyle={{ textAlign: "right" }}
                            >
                                {contract.applicationNo}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label="วันที่อนุมัติ :"
                                labelStyle={{ textAlign: "right" }}
                            >
                                {moment(contract.approveDate).format(dateFormat)}
                            </Descriptions.Item>
                        </Descriptions>
                        <Space/>
                        <Form.Item
                            label="วันที่สัญญา"
                            name="contract_date"
                            key="contract_date"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: 'กรุณาระบุวันที่สัญญา!',
                                    whitespace: true,
                                  },
                            ]}
                        >
                            <DatePicker 
                                locale={th_loc}
                                onChange={onChangeContractDate}
                                format={dateFormat}
                            />
                        </Form.Item>
                        <Form.Item
                            label="วันที่โอนเงิน"
                            name="pay_date"
                            key="pay_date"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: 'กรุณาระบุวันที่โอนเงิน!',
                                    whitespace: true,
                                  },
                            ]}
                        >
                            <DatePicker 
                                locale={th_loc}
                                // onChange={onChangeContractDate}
                                format={dateFormat}
                            />
                        </Form.Item>

                        <Table
                            columns={columns}
                            dataSource={contractDebitLists}
                            size="small"
                            rowKey={(item: ContractDebit) => item.id}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ContractForm
