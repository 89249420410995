import { Button, Col, DatePicker, Input, message, Modal, Row } from 'antd'
import {Form} from 'antd';
import PageHeader from 'src/layouts/PageHeader'
import React, { useEffect, useState } from 'react'
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';

import campaignService from 'src/services/master/campaignService';
import { Campaign } from 'src/domain/master/CampaignType';
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import TextArea from 'antd/lib/input/TextArea';


const dateFormat = 'DD/MM/YYYY';

interface IProps {
    id: number;
    mode: string;
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

const CampaignForm: React.FC<IProps> = (props) => {
    const _id = props.id;
    const mode = props.mode;

    const history = useHistory();
    const [form] = Form.useForm();
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);

    const [canSave, setCanSave] = useState<boolean>(true);

    useEffect(() => {
        if (mode === "EDIT") {
            const res = campaignService.getCampaignById(_id).then(res=>{
                setCampaign(res.data);
            });
        }
    }, []);

    useEffect(() => {

    }, [canSave]);

    useEffect(() => {
        form.setFieldsValue({
            campaign_code : campaign.campaignCode,
            campaign_name : campaign.campaignName,
            // start_date : campaign.startDate,
            start_date : campaign.startDate ? moment(campaign.startDate, 'YYYY-MM-DD') : "",
            end_date : campaign.endDate ? moment(campaign.endDate, 'YYYY-MM-DD') : "",
            remark : campaign.remark,
        });
    },[campaign]);

    const campaignCodeKeyUp = (value : any) => {
        const _code = form.getFieldValue("campaign_code");
        const res = campaignService.isExistCode(_code).then(res=>{
            if(res.data) {
                setCanSave(false);
                // message.error('Code "' + _code + '" is used', 2);
                form.setFields([
                    {
                      name: 'campaign_code',
                      errors: ['Code "' + _code + '" is used'],
                    },
                ]);
            } else {
                setCanSave(true);
            }
        });
    }

    const onChangeStartDate  = (value : any) => {
        console.log("start date", value ? value.format('YYYYMMDD') : "")
    }

    const onChangeEndDate  = (value : any) => {
        console.log("end date", value ? value.format('YYYYMMDD') : "")
    }

    const onFinish = async (values: any) => {
        // console.log("values ", values)
        const _campaign: Campaign  = {
            ...campaign,
            id : _id,
            campaignCode : values.campaign_code,
            campaignName : values.campaign_name,
            startDate : values.start_date.format('YYYYMMDD'),
            endDate : values.end_date.format('YYYYMMDD'),
            remark : values.remark,
        };
        setCampaign(_campaign);

        if (mode === "NEW") {
            await campaignService.addCampaign(_campaign).then(res => {
                // console.log("add id ", res.data.id)
                if(res.status === 200)
                    history.replace("/app/campaign/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        } else if(mode === "EDIT") {
            await campaignService.editCampaign(_campaign).then(res => {
                if(res.status === 200)
                    history.replace("/app/campaign/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> NewCampaign:", errorInfo);
        // setTrigger(false);
    };

    return (
        <>
            <PageHeader
                title={mode + " CAMPAIGN"}
                onClose={() => history.goBack()}
            >
                {canSave ?
                    <Button
                        size="large"
                        type="primary"
                        danger
                        icon={<SaveOutlined />}
                        onClick={() => form.submit()}
                    >
                        Save
                    </Button>
                :""
                }
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                >
                    <Panel>
                        <div style={{ margin: "10px 10px" }}>
                    {/* <Row style={{ margin: "10px 10px" }}>
                        <Col span="24"> */}
                            <Form.Item
                                label="CAMPAIGN CODE"
                                name="campaign_code"
                                key="campaign_code"
                                rules={[
                                    { required: true, message: "กรุณาระบุรหัส Campaign!" },
                                    {
                                    message: "Please input campaign code",
                                    },
                                ]}
                            >
                                <Input 
                                    onKeyUp={campaignCodeKeyUp} 
                                    readOnly={campaign.id ? true : false}
                                    style={{width:"30%"}}
                                />
                            </Form.Item>
                            <Form.Item
                                // {...layout}
                                label="CAMPAIGN"
                                name="campaign_name"
                                key="campaign_name"
                                rules={[
                                    { required: true, message: "กรุณาระบุชื่อ Campaign!" },
                                    {
                                    message: "Please input campaign code",
                                    },
                                ]}
                            >
                                <Input style={{width:"90%"}}/>
                            </Form.Item>
                            <Form.Item
                                // {...layout}
                                label="วันที่เริ่มต้น"
                                name="start_date"
                                key="start_date"
                                rules={ [
                                    {
                                      type: 'object',
                                      required: true,
                                      message: 'กรุณาระบุวันที่เริ่มต้น!',
                                      whitespace: true,
                                    },
                                    // {
                                    //     message: "Please input start date",
                                    // }
                                ]}
                                // rules={[
                                //     { type: 'object', required: true, message: "กรุณาระบุวันที่เริ่มต้น!" },
                                //     {
                                //     message: "Please input start date",
                                //     },
                                // ]}
                            >
                                <DatePicker 
                                    locale={th_loc}
                                    onChange={onChangeStartDate}
                                    // defaultValue={moment(moment().format('L'), dateFormat)}
                                    format={dateFormat}
                                    
                                />
                            </Form.Item>
                            <Form.Item
                                // {...layout}
                                label="วันที่สิ้นสุด"
                                name="end_date"
                                key="end_date"
                                rules={ [
                                    {
                                      type: 'object',
                                      required: true,
                                      message: 'กรุณาระบุวันที่สิ้นสุด!',
                                      whitespace: true,
                                    },
                                ]}
                            >
                                <DatePicker 
                                    locale={th_loc}
                                    onChange={onChangeEndDate}
                                    // defaultValue={moment(moment().format('L'), dateFormat)}
                                    format={dateFormat}
                                    
                                />
                            </Form.Item>
                            <Form.Item
                                // {...layout}
                                label="หมายเหตุ"
                                name="remark"
                                key="remark"
                                
                            >
                                <TextArea rows={3} cols={40} style={{width:"90%"}} maxLength={255} />
                            </Form.Item>
                        {/* </Col>
                    </Row> */}
                    </div>
                    </Panel>
                </Form>
            </Content>
        </>
    );
};

export default CampaignForm
