import React, { useEffect, useState } from 'react'
import { Button, Col, Descriptions, Modal, Popconfirm, Row, Space, Steps, Tabs, Typography, Form, Input } from 'antd'
import { RouteComponentProps, useHistory } from 'react-router';
import { FormOutlined, SendOutlined, LikeOutlined, DislikeOutlined , CloseSquareOutlined, DeleteOutlined, CheckSquareOutlined} from "@ant-design/icons";
import PageHeader from 'src/layouts/PageHeader'
import applicationService from "src/services/application/applicationService"
import { Application } from 'src/domain/application/applicationType';
import ViewPersonalApplication from './ViewPersonalApplication';
import { Content } from 'antd/lib/layout/layout';
import { Address } from 'src/domain/application/addressType';
import ViewOccupationApplication from './ViewOccupationApplication';
import ViewIncomeApplication from './ViewIncomeApplication';
import ViewLoanApplication from './ViewLoanApplication';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";
import ViewApplicationMovement from './ViewApplicationMovement';
import RemarkModel from './RemarkModel';
import ViewApplicationResouce from './ViewApplicationResouce';

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const { Step } = Steps;
const dateFormat = 'DD/MM/YYYY';

interface IParams {
    id: string;
}

const ViewApplication : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _applicationId = Number(props.match.params.id);
    const [application, setApplication] = useState<Application>({} as Application);
    const [addressCurrent, setAddressCurrent] = useState<Address>({} as Address);
    const [addressOffice, setAddressOffice] = useState<Address>({} as Address);
    const [currentState, setCurrentState] = useState<number>(0);
    // const [_appId, _setAppId] = useState<number>(0);
    const history = useHistory();

    const [appStatus, setAppStatus] = useState<string>("");
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const showModal = (status: string) => {
        setAppStatus(status);
        setModalVisible(true);
    };

    const handleOK = (value: string) => {
        console.log("ok value", value)
        // setTimeout(() => {
            if(appStatus === 'submitApprove')
                onClickSubmitApproval(value);
            if(appStatus === 'approveCredit')
                onClickApproveCredit(value);
            else if(appStatus === 'disapproval')
                onClickDisapproval(value);
        // }, 2000);
      };

    const handleCancel = () => {
        setAppStatus("");
        setModalVisible(false);
    };
  
    useEffect(() => {
        let id: number = Number(props.match.params.id);
        applicationService.getApplicationById(id).then(res=>{
            console.log(res.data);
            const _app = res.data;
            setApplication(_app);
            // _setAppId(_app.id);
            if(_app.applicationStatusId) {
                if(_app.applicationStatusCode === '00') setCurrentState(0);
                else if(_app.applicationStatusCode === '01') setCurrentState(1);
                // else if(_app.applicationStatusCode === '02') setCurrentState(2);
                else if(_app.applicationStatusCode === '99') setCurrentState(2);
                // else if(_app.applicationStatusCode === 'CC') setCurrentState(4);
            }

            for(let x=0; x<_app.addresses.length; x++) {
                const _addr = _app.addresses[x];
                console.log("_addr", _addr)
                if(_addr.addressType === "CURRENT") setAddressCurrent(_addr);
                else setAddressOffice(_addr);
            }
            
        });
    }, []);

    const onClickSubmitApproval = (e: string) => {
        console.log("submit credit approval", e)
        applicationService.updateApplicationStatus(_applicationId, "01", e).then((res) => {
            if (res.status === 200) {
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const onClickApproveCredit = (e: string) => {
        console.log("approve credit", e)
        applicationService.updateApplicationStatus(_applicationId, "99", e).then((res) => {
            if (res.status === 200) {
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const onClickDisapproval = (e: string) => {
        console.log("disapprove credit", e)
        applicationService.updateApplicationStatus(_applicationId, "02", e).then((res) => {
            if (res.status === 200) {
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const onClickDelete = (e: any) => {
        const _appId = application.id ? application.id : 0;
        console.log("_appId", _appId)
        applicationService.updateApplicationStatus(_appId, "CC", "").then((res) => {
            if (res.status === 200) {
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const cancel = () => {

    }

    return (
        <>
            <PageHeader title={'APP NO. '+application.applicationNo} onClose={() => history.push("/app/application")}>
                <Space>
                    {application.applicationStatusCode === '00' ?
                        <Button
                            size="large"
                            icon={<SendOutlined />}
                            // onClick={onClickSubmitApproval}
                            onClick={() => showModal("submitApprove")}
                            >
                            ส่งอนุมัติ
                        </Button>
                    :""}
                    {application.applicationStatusCode === '01' ?
                    <Button
                        size="large"
                        icon={<LikeOutlined />}
                        // onClick={onClickApproveCredit}
                        onClick={() => showModal("approveCredit")}
                        >
                        อนุมัติ
                    </Button>
                    :""}
                    {application.applicationStatusCode === '01' ?
                    <Button
                        size="large"
                        icon={<DislikeOutlined />}
                        // onClick={onClickDisapproval}
                        onClick={() => showModal("disapprove")}
                        >
                        ไม่อนุมัติ
                    </Button>
                    :""}
                    {application.applicationStatusCode === '00' ?
                    <Button
                        size="large"
                        icon={<FormOutlined />}
                        onClick={() => history.push("/app/application/edit/" + _applicationId)}
                        >
                        แก้ไข
                    </Button>
                    :""}
                    {application.applicationStatusCode === '00' ?
                    <Popconfirm
                        title="ต้องการลบใบคำขอสินเชื่อใช่หรือไม่?"
                        onConfirm={onClickDelete}
                        onCancel={cancel}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                    <Button
                        size="large"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                        // onClick={onClickDelete}
                        >
                        ลบ
                    </Button>
                    </Popconfirm>
                   :""}
                    {/* <Button
                        size="small"
                        icon={<CloseSquareOutlined />}
                        onClick={() => history.goBack()}
                        >
                        Cancel
                </Button> */}
                </Space>
            </PageHeader>
            <Content className="app-page-content">
                <Descriptions
                    size="middle"
                    layout='vertical'
                >
                    <Descriptions.Item span={2}>
                        {(application.applicationStatusCode !== '02' && application.applicationStatusCode !== 'CC') ?
                            <Steps current={currentState} >
                                <Step title="สร้าง" icon={<FormOutlined />} />
                                <Step title="รออนุมัติ" icon={<SendOutlined />} />
                                <Step title="อนุมัติ" icon={<LikeOutlined  />} />
                            </Steps>
                        :
                            <label style={{fontWeight: "bold", fontSize:"medium", color:"red"}}>
                                สถานะ : {application.applicationStatusName}
                            </label>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item
                        style={{marginLeft:50}}
                        label=""
                    >
                        <Descriptions layout="horizontal" column={1} style={{marginLeft:30}}>
                            <Descriptions.Item
                                label="วันที่ "
                                labelStyle={{ textAlign: "right", fontWeight: "bold", fontSize:"medium"}}
                            >
                                <label style={{fontWeight: "bold", fontSize:"medium"}}>{moment(application.applicationDate).format(dateFormat)}</label>
                            </Descriptions.Item>
                            {application.contractId ?
                                <Descriptions.Item
                                    label="เลขที่สัญญา "
                                    labelStyle={{ textAlign: "right", fontWeight: "bold", fontSize:"medium"}}
                                >
                                <label style={{fontWeight: "bold", fontSize:"medium"}}>{application.contractNo}</label>
                                </Descriptions.Item>
                            :""}
                        </Descriptions>
                    </Descriptions.Item>
                </Descriptions>

                {/* <Row>
                    <Col offset={17} style={{ marginTop:6, fontWeight: "bold" }}>
                        <label style={{fontWeight: "bold", fontSize:"medium"}}>วันที่ : {moment(application.applicationDate).format(dateFormat)}</label>
                    </Col>
                    {application.contractId ?
                        <Col offset={17} style={{ marginTop:6, fontWeight: "bold" }}>
                            <label style={{fontWeight: "bold", fontSize:"medium"}}>เลขที่สัญญา : {application.contractNo}</label>
                        </Col>
                    :""
                    }
                    <Col span={15} style={{ marginTop:16 }}>
                        {(application.applicationStatusCode !== '02' && application.applicationStatusCode !== 'CC') ?
                        <Steps current={currentState} >
                            <Step title="สร้าง" icon={<FormOutlined />} />
                            <Step title="รออนุมัติ" icon={<SendOutlined />} />
                            <Step title="อนุมัติ" icon={<LikeOutlined  />} />
                            // <Step title="ไม่อนุมัติ" icon={<DislikeOutlined   />} />
                            // <Step title="ยกเลิก" icon={<CloseSquareOutlined  />} />
                        </Steps>
                        :
                        <Typography>
                            <Title level={3}>สถานะ : {application.applicationStatusName}</Title>
                        </Typography>
                        }
                    </Col>
                </Row> */}

                <Tabs defaultActiveKey="1">
                    <TabPane tab="ข้อมูลส่วนตัว" key="1" >
                        <div className={classes.dataPanel}>
                            <ViewPersonalApplication application={application} addressCurrent={addressCurrent}/>
                        </div>
                    </TabPane>
                    <TabPane tab="อาชีพ/สถานที่ทำงาน" key="2">
                        <div className={classes.dataPanel}>
                            <ViewOccupationApplication addressOffice={addressOffice}/>
                        </div>
                    </TabPane>
                    <TabPane tab="รายได้" key="3">
                        <div className={classes.dataPanel}>
                            <ViewIncomeApplication application={application}/>
                        </div>
                    </TabPane>
                    <TabPane tab="วงเงินกู้" key="4">
                        <div className={classes.dataPanel}>
                            <ViewLoanApplication application={application}/>
                        </div>
                    </TabPane>
                    <TabPane tab="ไฟล์แนบ" key="5">
                        <div className={classes.dataPanel}>
                            <ViewApplicationResouce id={_applicationId} />
                        </div>
                    </TabPane>
                    <TabPane tab="ความเคลื่อนไหว" key="6">
                        <div className={classes.dataPanel}>
                            <ViewApplicationMovement id={_applicationId} />
                        </div>
                    </TabPane>
                </Tabs>
            </Content>

            <RemarkModel
                modalVisible={modalVisible}
                onOK={handleOK}
                onCancel={() => handleCancel()}
            />
        </>
    )
}

export default ViewApplication
