import React, { useEffect, useState } from 'react'
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";
import { Form, Button, Col, Row, Steps } from "antd";
import { Affix } from "antd";
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  SaveOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import PersonalForm from "./PersonalApplication";
import OccupationForm from "./OccupationApplication";
import IncomeForm from "./IncomeApplication";
import LoanForm from "./LoanApplication";
import applicationService from "src/services/application/applicationService";
import { Application } from "src/domain/application/applicationType";
import { Address } from "src/domain/application/addressType";
import { IncomeSource } from "src/domain/application/incomeSourceType";
import Panel from "../../components/container/Panel";
import Flex from "../../components/container/Flex";
import classes from "./NewApplication.module.css";

const Step = Steps.Step;

interface IProps {
  id: number;
  mode: string;
}

const ApplicationForm : React.FC<IProps> = (props) => {
  const id = props.id;
  const mode = props.mode;
  console.log("mode app", mode)
  const [trigger, setTrigger] = useState<boolean>(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [currentState, setCurrentState] = useState<number>(0);
  // const [id, setId] = useState<number>(0);

  const [application, setApplication] = useState<Application>(
    {} as Application
  );
  const [addressCurrent, setAddressCurrent] = useState<Address>({} as Address);
  const [addressOffice, setAddressOffice] = useState<Address>({} as Address);

  const [incomeNormal, setIncomeNormal] = useState<IncomeSource>(
    {} as IncomeSource
  );
  const [incomeOther, setIncomeOther] = useState<IncomeSource>(
    {} as IncomeSource
  );

  const next = () => {
    setCurrentState((prev) => prev + 1);
  };
  const prev = () => {
    setCurrentState((prev) => prev - 1);
  };
  const changeSteps = (curr: number) => {
    form
    .validateFields()
    .then(() => {
      setCurrentState(curr);
    })
    .catch((err) => console.log(err));

    // setCurrentState(curr);

    console.log("changeSteps : ", application);
    // setApplication({ ...application });
  };

  useEffect(() => {
    console.log("mode", mode)
    if(mode === 'EDIT') {
      (async () => {
        const res = await applicationService.getApplicationById(id);
        // setApplication(res.data);
        const _app = res.data;
       
        for(let x=0; x<_app.addresses.length; x++) {
          const _addr = _app.addresses[x];
          if(_addr.addressType === "CURRENT") setAddressCurrent(_addr);
          else setAddressOffice(_addr);
        }

        setApplication(_app);
      })();
    }
  }, []);

  useEffect(() => {
    console.log("application param", application);
    if (application.isFinish) saveDataToDB();
  }, [application]);

  const updatePersonalData = (personalData: any) => {
    const _app = {
      ...application,
      branchId: personalData.branchId,
      // branchCode: personalData.branchCode,
      prenameThaiId: personalData.prenameThaiId,
      prenameThaiName: personalData.prenameThaiName,
      prenameEngId: personalData.prenameEngId,
      prenameEngName: personalData.prenameEngName,
      nameThai: personalData.nameThai,
      nameEng: personalData.nameEng,
      birthday: personalData.birthday,
      nationality: personalData.nationality,
      idCard: personalData.idCard,
      marriageStatusId: personalData.marriageStatusId,
      marriageStatusName: personalData.marriageStatusName,
      livingStatusId: personalData.livingStatusId,
      livingStatusName: personalData.livingStatusName,
      mobile: personalData.mobile,
      phone: personalData.phone,
      email: personalData.email,
      lineId: personalData.lineId,
      facebook: personalData.facebook,
      decitionDocChannalId: personalData.decitionDocChannalId,
      decitionDocChannalName: personalData.decitionDocChannalName,
    };
    setApplication(_app);

    const _addr_curr = {
      ...addressCurrent,
      addressType: "CURRENT",
      building: personalData.building,
      roomNo: personalData.roomNo,
      address: personalData.address,
      moo: personalData.moo,
      soi: personalData.soi,
      road: personalData.road,
      tambonCode: personalData.tambonCode,
      tambonName: personalData.tambon,
      amphurCode: personalData.amphurCode,
      amphurName: personalData.amphur,
      provinceCode: personalData.provinceCode,
      provinceName: personalData.province,
      zipcode: personalData.zipcode,
    };
    setAddressCurrent(_addr_curr);
  };

  const updateOccupationData = (data: any) => {
    const _addr_office = {
      ...addressOffice,
      addressType: "OFFICE",
      building: data.building,
      roomNo: data.roomNo,
      address: data.address,
      moo: data.moo,
      soi: data.soi,
      road: data.road,
      tambonCode: data.tambonCode,
      tambonName: data.tambon,
      amphurCode: data.amphurCode,
      amphurName: data.amphur,
      provinceCode: data.provinceCode,
      provinceName: data.province,
      zipcode: data.zipcode,
      officeName: data.officeName,
      phone: data.phone,
      businessType: data.businessType,
      occupationId: data.occupationId,
      occupationName: data.occupationName,
    };
    setAddressOffice(_addr_office);
  };

  const updateIncomeData = (data: any) => {
    const _app = {
      ...application,
      incomeAmt: data.incomeAmt,
      otherIncomeAmt: data.otherIncomeAmt,
      invoiceLocationId : data.invoiceLocationId,
    };
    setApplication(_app);

    if (data.selectIncome) {
      const _income = {
        ...incomeNormal,
        incomeType: "NORMAL",
        incomeSourceId: data.selectIncome.id,
        incomeSourceName: data.selectIncome.name,
      };
      setIncomeNormal(_income);
    }

    if (data.selectIncomeOther) {
      const _incomeOther = {
        ...incomeOther,
        incomeType: "OTHER",
        incomeSourceId: data.selectIncomeOther.id,
        incomeSourceName: data.selectIncomeOther.name,
      };
      setIncomeOther(_incomeOther);
    }
  };

  const updateLoanData = (data: any) => {
    const _app = {
      ...application,
      loanAmt: data.loanAmt,
      loanInstallment: data.loanInstallment,
      campaignId: data.campaignId,
      campaignHeadId: data.campaignHeadId,
      accountName: data.accountName,
      bankId: data.bankId,
      bankCode: data.bankCode,
      bankName: data.bankName,
      bankAccountNo: data.bankAccountNo,
      isFinish: true,
    };
    setApplication(_app);
  };

  const saveDataToDB = () => {
    const _app: Application = {
      ...application,
      addresses: [],
      incomeSource: [],
    };

    addressCurrent && _app.addresses.push(addressCurrent);
    addressOffice && _app.addresses.push(addressOffice);

    incomeNormal && _app.incomeSource.push(incomeNormal);
    incomeOther && _app.incomeSource.push(incomeOther);

    console.log("_application::saveDataToDB", _app);

    if(mode === 'NEW') {
      applicationService
      .addApplication(_app)
      .then((res) => {
        if (res.status === 200) history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      applicationService
      .updateApplication(_app)
      .then((res) => {
        if (res.status === 200) history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
    }
    
  };

  const steps = [
    {
      title: "ข้อมูลส่วนตัว",
      content: (
        <PersonalForm
          id={id}
          application={application}
          addressCurrent={addressCurrent}
          updatePersonalData={updatePersonalData}
          trigger={trigger}
          setTrigger={setTrigger}
          next={next}
          // form={form}
        />
      ),
    },
    {
      title: "อาชีพ/สถานที่ทำงาน",
      content: (
        <OccupationForm
          addressOffice={addressOffice}
          setAddressOffice={setAddressOffice}
          trigger={trigger}
          setTrigger={setTrigger}
          updateOccupationData={updateOccupationData}
          next={next}
          // form={form}
        />
      ),
    },
    {
      title: "รายได้",
      content: (
        <IncomeForm
          application={application}
          trigger={trigger}
          setTrigger={setTrigger}
          updateIncomeData={updateIncomeData}
          next={next}
        />
      ),
    },
    {
      title: "วงเงินกู้",
      content: (
        <LoanForm
          application={application}
          setApplication={setApplication}
          trigger={trigger}
          setTrigger={setTrigger}
          updateLoanData={updateLoanData}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title="สร้าง ใบคำขอสินเชื่อ">
        <Button
          size="large"
          icon={<CloseCircleOutlined />}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      </PageHeader>

      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <div
          style={{ width: "100px", height: "100px", backgroundColor: "blue" }}
        ></div>
        <div
          style={{ width: "100px", height: "100px", backgroundColor: "red" }}
        ></div> */}
        <Affix
          //   className={classes.affixTop}
          offsetTop={70}
          onChange={(affixed) => console.log(affixed)}
        >
          <Panel>
            <div style={{ height: "400px", display: "flex", width: "220px" }}>
              <Steps
                current={currentState}
                direction="vertical"
                onChange={(e) => changeSteps(e)}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
          </Panel>
        </Affix>

        <Panel style={{ marginLeft: "30px", width: "100%", padding: "40px" }}>
          <div>{steps[currentState].content}</div>
        </Panel>
      </div>
    </>
  );
};

export default ApplicationForm;