import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/application";
// const url = "http://192.168.1.37:8080/application";
const url = `${configs.urlRoot}/application`;

const getApplications = () => {
    return http.get(url);
};

const getApplicationById = (id: number) => {
    return http.get(url+"/"+id);
}

const getApplicationByNo = (no: string) => {
    return http.get(url+"/byNo?no="+no);
}

const getApplicationByStatus = (statusId: number) => {
    return http.get(url+"/byStatus?statusId="+statusId+"&orderBy=application_no");
}

const addApplication = (app : any) => {
    return http.post(url, app);
} 

const updateApplication = (app : any) => {
    return http.put(url, app);
}

const updateApplicationStatus = (appId : number, statusCode: string, remark: string) => {
    return http.put(url+"/updateStatus/"+appId+"/"+statusCode+"/"+remark);
}

const updateAttatchFile = (applicationId:number, idcardResourceId: number | null, bookBankResourceId: number | null) => {
    return http.post(`${url}/updateAttatchFile`, {applicationId: applicationId, idcardResourceId: idcardResourceId, bookBankResourceId: bookBankResourceId});
}

const getApplicstionResourceByApplicationId = (applicationId:number) => {
    return http.get(`${url}/getApplicationResource/${applicationId}`)
}

export default {
    getApplications,
    getApplicationById,
    getApplicationByNo,
    getApplicationByStatus,
    addApplication,
    updateApplication,
    updateApplicationStatus,
    updateAttatchFile,
    getApplicstionResourceByApplicationId,
}