import { log } from "console";
import http from "../../lib/http";
import configs from "src/constants/config";
import { Branch } from "src/domain/master/BranchType";

// const url = "http://localhost:8080/branch";
const url = `${configs.urlRoot}/branch`;

const getBranchUseAll = () => {
    return http.get(url);
}

const getById = (branchId: number) => {
    return http.get(url+"/"+branchId);
}

const searchBranch = (keyword: string, useFlg: number) => {
    let searchData = {keyword: keyword, useFlg: useFlg};
    return http.post(`${url}/search`, {}, {params: searchData})
}

const add = (submitData: Branch) => {
    return http.post(`${url}/add`, submitData);
}

const update = (submitData: Branch) => {
    return http.post(`${url}/update`, submitData);
}

export default {
    getBranchUseAll,
    searchBranch,
    add,
    update,
    getById
}